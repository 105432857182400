@import "./variables";

.shop-detail {
    @extend .info-text;
    @extend .font-primary;

    @media screen and (max-width: 1200px){
        width: 57em;
        overflow-x: auto;
    }

    .ribbin {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .live-filter {
        @extend .info-text;
        @extend .font-primary;
        width: 100%;
    }

    .image-bw {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }

    .row {
        margin: 0;
    }

    .align-center {
        display: flex;
        align-items: center;
        &-right {
            justify-content: flex-end;
        }
    }

    .align-top {
        display: flex;
    }

    .btn-text {
        &-live {
            color: $Orange;
        }
    }

    .shop-detail-header {
        background-color: $White;
        padding: 1rem;

        font-family: "DBHeavent" !important;
        font-style: normal;
        font-weight: 300;

        .row {
            margin: 0;
        }

        .col-1-atl {
            flex: 0 0 10.333333%;
            max-width: 10.333333%;
            justify-content: center;
            display: flex;
        }
        .col-4-atl {
            flex: 0 0 31.333333%;
            max-width: 31.333333%;
        }

        .right-side-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .shop-profile-image {
            max-width: 100px;
            max-height: 50px;
            // border-radius: 50%;
        }
    }

    .shop-detail-content {
        margin-top: 1rem;

        h4 {
            margin: 0;
        }

        .live-container {
            .live-header {
                padding: 1rem;
                background-color: white;
            }

            .live-content {
                margin-top: 1rem;

                .empty-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50vh;
                    flex-direction: column;
                }

                .live-list {
                    // padding: 1rem;
                    // background-color: white;

                    .live {
                        padding: 1rem;
                        background-color: white;
                        margin: 1rem 0;

                        .live-product-preview {
                            position: relative;
                            .now-live {
                                // position: absolute;
                                // top: 4px;
                                // right: 6px;
                            }
                        }
                    }
                }

                .live-detail {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;
                }
                .live-product-preview {
                    width: 100%;
                }
                .live-product-preview img {
                    // max-width: 208px;
                    width: 100%;
                }

                .product-list {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: flex-start;
                    width: 100%;
                }

                .product-image {
                    display: flex;
                    margin-right: 10px;
                    width: 15%;
                    max-width: 100px;
                }

                .product-image img {
                    width: 100%;
                    max-width: 94px;
                }
            }
        }

        .product-container {
            .product {
                &-header {
                    padding: 1rem;
                    background-color: white;
                }

                &-content {
                    .product-list {
                        .col-2-5 {
                            flex: 0 0 20.33%;
                            max-width: 20.33%;
                            padding: 1rem 0;
                        }
                        .col-1-5 {
                            flex: 0 0 12.33%;
                            max-width: 12.33%;
                            padding: 1rem 0;
                        }
                        .product-table-header {
                            font-size: $caption;
                            margin-top: 1rem;
                            background-color: $defaultBMain;
                            color: $White;
                            border-radius: 5px 5px 0 0;

                            .col-1 {
                                flex: 0 0 7.69%;
                                max-width: 7.69%;
                            }
                            .col-2 {
                                flex: 0 0 15.38%;
                                max-width: 15.38%;
                            }

                            .row {
                                margin: 0;
                                .col-1,
                                .col-2 {
                                    padding: 1rem 0 !important;
                                }
                            }
                        }
                        
                        .product-table-content {
                            // background-color: $White;
                            font-size: $caption;
                            line-height: 1.35rem;
                            
                            .header-style {
                                background-color: black !important;
                                color: white;
                                border-radius: 10px 10px 0 0;
                                margin-bottom: 0px !important;
                            }
                            .product-row {
                                padding: 1rem 0;
                                background-color: $White;
                                margin: 0 0 1rem 0 !important;
                            }

                            .product-row .product-category {
                                svg {
                                    width: auto;
                                    height: auto;
                                    max-width: 15px;
                                    path {
                                        fill: $infoMain;
                                    }
                                }
                            }

                            .product-name {
                                .product-detail {
                                    padding-left: 10px;
                                    ul {
                                        padding: 0;
                                        li {
                                            list-style: none;
                                        }
                                    }

                                    .label-product-name {
                                        font-weight: 600;
                                    }
                                }

                                .product-image {
                                    width: 100%;
                                    padding: 0.3rem;
                                    img {
                                        width: 100%;
                                    }
                                }
                            }

                            .row {
                                .col-1 {
                                    flex: 0 0 7.69%;
                                    max-width: 7.69%;
                                }
                                .col-2 {
                                    flex: 1 0 15.38%;
                                    max-width: 15.38%;
                                }
                                .col-7 {
                                    flex: 0 0 53.84%;
                                    max-width: 53.84%;
                                }
                                .col-6 {
                                    flex: 0 0 46.15%;
                                    max-width: 46.15%;
                                }
                                margin: 0;
                                .col-1,
                                .col-2 {
                                    // padding: 1rem 0;
                                }
                                .col-1-atl {
                                    flex: 0 0 16.66%;
                                    max-width: 16.66%;
                                }
                                .col-2-atl {
                                    flex: 1 0 10.38%;
                                    max-width: 10.38%;
                                    word-break: break-all;
                                }
                            }

                            .product-action {
                                margin-top: -8px;
                                path {
                                    fill: $defaultInfoMain;
                                }
                            }
                        }
                    }
                }
            }
        }

        .detail-container {
            .detail {
                &-header {
                    background-color: white;
                }

                &-content {
                    padding: 1em 5em;
                    background-color: white;
                    .shop-profile-image {
                        max-width: 300px;
                        max-height: 200px;
                        // border-radius: 50%;
                    }
                }

                &-footer {
                    margin-top: 1em;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    // button {
                    //     background: $AISPrimaryMain;
                    //     box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    //     border-radius: 10px;
                    //     color: $defaultTextPrimary;
                    //     border: 1px solid $AISPrimaryMain;
                    //     width: 160px;
                    //     height: 44px;

                    //     &.outline {
                    //         background: #ffff;
                    //         color: $AISPrimaryMain;
                    //         border: 1px solid $AISPrimaryMain;
                    //     }
                    // }
                }
            }
        }
    }

    .col-2.text-right.align-middle.link {
        cursor: pointer;
        color: $defaultInfoMain;
        svg {
            path {
                fill: $defaultInfoMain;
            }
        }
    }

    .pagination-top {
        display: flow-root;
        margin-bottom: 10px;
    }

    .shop-detail-general-container {
        font-size: $body2;
        background-color: $White;
        .flex-column {
            display: flex;
            flex-flow: column;
            // justify-content: center;
            // align-items: center;
        }

        .flex-row {
            display: flex;
            flex-flow: row;
            // justify-content: center;
            // align-items: center;
        }

        img {
            // border-radius: 50%;
            // max-width: 10em;
            // width: 100px;
            // height: auto;
            // object-fit: contain;
        }

        .circular--portrait {
            position: relative;
            max-width: 10em;
            height: auto;
            overflow: hidden;
            border-radius: 50%;
        }
        .circular--portrait img {
            width: 100%;
            height: auto;
        }

        .require::after {
            content: " * ";
            color: red;
        }

        .w-45 {
            width: 45%;
        }

        .shop-detail-container {
            padding: 2em 7em 4em 7em;
            background-color: $White;
            margin-bottom: 20px;
        }

        input {
            background-color: $lightGray;
            font-size: $caption;
        }

        input:focus {
            border: 0;
            outline: 0;
        }

        textarea {
            background: #f9f9f9;
            box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            border: 0;
            padding: 1em;
            font-size: $caption;
        }

        textarea:focus {
            border: 0;
            outline: 0;
        }

        .shop-tax-radio {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            
            .radio-group{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }

        .ant-radio-checked .ant-radio-inner {
            border-color: $success !important;
            border: 1px solid;
        }
        .ant-radio-inner::after {
            width: 12px !important;
            height: 12px !important;
            background-color: $success !important;
        }
        .ant-radio-inner {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            width: 16px;
            height: 16px;
            /* background-color: orange; */
            border-color: $AISPrimaryMain;
            border-style: solid;
            border-width: 1px;
            border-radius: 100px;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            //   border: 1px solid green;
            //   -webkit-text-fill-color: green;
            //   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
            @include primary-font;
            transition: background-color 5000s ease-in-out 0s;
            animation: clearAutofill forwards;
        }

        .form-control:focus {
            color: #495057;
            background-color: #fff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        .ant-select-selection-item {
            -ms-flex: 1;
            flex: 1 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include primary-font;
        }

        .tax-active {
            padding: 2em 7em 2em 7em;
            margin-bottom: 1em;
        }
    }

    .react-tabs__tab {
        margin-right: 1px;
    }

    .inner-addon {
        position: relative;
    }

    .inner-addon .glyphicon {
        position: absolute;
        pointer-events: none;
    }

    .left-addon .glyphicon {
        left: 0px;
    }
    .right-addon .glyphicon {
        right: 0px;
        margin-right: 10px;
        top: 20%;
        z-index: 1;
    }

    .left-addon input {
        padding-left: 30px;
    }
    .right-addon input {
        padding-right: 30px;
    }

    .icon-manage {
        path {
            fill: $defaultInfoMain;
        }
    }
}

.btn {
    margin: 0rem 0.5rem;
    border-radius: 10px !important;
    min-width: 160px;
}
.btn-ignore {
    background-color: #ffffff !important;
    color: #ed1072 !important;
    border: solid #ed1072 1px !important;
}
.btn-confirm {
    background-color: green !important;
    color: $defaultTextPrimary !important;
    width: 6em;
    display: inline-block;
    // height: 2em;
}

.shop-member-info {
    .shop-member-container {
        .bg-white {
            padding: 1em 4em;
            background-color: $White;
        }
        .shop-member-active {
            line-height: 20px;
            padding-top: 1em;
        }
        .shop-member-preview {
            svg {
                path {
                    fill: $AISPrimaryMain;
                }
            }
        }
        .modal-preview {
            position: relative;
            // border: 1px solid black;
            width: auto;
            display: flex;
            justify-content: center;
            position: absolute;
            background-color: white;
            padding: 2em;
            flex-direction: column;
            align-items: center;
            width: 30%;
            min-width: 10em;
            box-shadow: 4px 4px 10px rgba(41, 45, 47, 0.1), -4px 4px 10px rgba(255, 255, 255, 0.6);
            border-radius: 5px;
        }
        .member-number-preview {
            background: #ffffff;
            border: 1px solid #f2f2f2;
            box-sizing: border-box;
            box-shadow: 4px 4px 10px rgba(41, 45, 47, 0.1), -4px 4px 10px rgba(255, 255, 255, 0.6);
            border-radius: 5px;
            padding: 0.2em 1em;
            position: absolute;
            left: 5%;
            top: 33%;
            width: 90%;
            height: 6vw;
        }
        .input-preview {
            background: #f9f9f9;
            /* InnerShadow */
            width: 23vw;
            height: 2vw;
            min-width: 90%;
            box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 52px;
            border: none;
        }

        @media only screen and (max-width: 750px){
            .member-number-preview {
                background: #ffffff;
                border: 1px solid #f2f2f2;
                box-sizing: border-box;
                box-shadow: 4px 4px 10px rgba(41, 45, 47, 0.1), -4px 4px 10px rgba(255, 255, 255, 0.6);
                border-radius: 5px;
                padding: 1em;
                position: absolute;
                left: 5%;
                top: 50%;
                width: 90%;
                height: 4em;
            }
        }

        @media only screen and (max-width: 564px){
            .member-number-preview {
                background: #ffffff;
                border: 1px solid #f2f2f2;
                box-sizing: border-box;
                box-shadow: 4px 4px 10px rgba(41, 45, 47, 0.1), -4px 4px 10px rgba(255, 255, 255, 0.6);
                border-radius: 5px;
                padding: 1em;
                position: absolute;
                left: 5%;
                top: 55%;
                width: 90%;
                height: 4em;
            }
        }
    }
}

.shop-member-info section {
    margin-top: 1em;
}

.break-word {
    word-break: break-word !important;
}

.selected-list-cat {
//   height: 21vh;
max-height: 21vh;
min-height: 10vh;
  display: flex;
//   flex-direction: column;
  flex-wrap: wrap;
  overflow: scroll;
  gap: 10px;
//   width: 70%;
  padding-top: 1em;
  padding-right: 10em;
}

.item-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    border: 1px solid #bfbfbf;
    padding: 5px;
    // flex: 45% !important;
    background-color: $defaultBg;

    .text-item-selected {
      @include primary-font();
      font-style: normal;
      font-weight: 100;
      font-size: $caption;
      color: $darkBlack;
    }
  }