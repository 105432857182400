@import "./variables";

.wrap-table-announce {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 2rem;

  .table-announce-header {
    display: grid;
    grid-template-columns: 50px 150px auto 180px 150px 120px;
    background: $defaultBMain;
    padding: 10px;
    color: $White;
    border-radius: 5px 5px 0px 0px;

    .header-announce-item {
      text-align: center;

      &:nth-child(3) {
        text-align: left;
      }
    }
  }

  .table-announce-body {
    display: grid;
    grid-template-columns: 50px 150px auto 180px 150px 120px;
    padding-top: 14px;
    color: #444450;
    align-items: center;

    .body-announce-item {
      background: $White;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      font-size: 20px;
      color: #444450;
      height: 80px;

      &:nth-child(3) {
        justify-content: flex-start;
      }

      svg {
        cursor: pointer;
      }

      .image-preview-announce {
        width: 50%;
        height: 100%;
        object-fit: contain;

        &.image-expired {
          filter: grayscale(1);
        }
      }

      .tag-status-announce {
        background: #f9fbef;
        padding: 2px 10px;
        font-size: 22px;
        color: #b2d234;
        border-radius: 5px;

        &.isExpiredDate {
          background: #e9e9e9;
          color: #aeafaf;
        }
      }

      .table-announce-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
    }
  }
}
