@import "./variables";

#login {
    background-color: $defaultBg;

    .container-logo{
        padding: 1em 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .border-logo {
        padding: 5px 15px;
        border-left: 2px solid $defaultBg;
    }
    .circle {
        ::after {
            content: "asdasd";
        }
    }
    .backgroud-login {
        background-color: $lightYellow;
    }

    .login-container {
        height: 94vh;
        position: relative;
        // @extend .backgroud-login;
    }

    &.overflow-page {
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
    }

    .nav-container {
        padding: 0.3em 1em;
        background-color: #ffffff;
        border-bottom: 1px solid #e5e5e5;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        z-index: 2;
    }

    .nav-login {
        width: 100%;
        // height: 1em;
    }

    .login-form {
        // border: 1px solid black;
        justify-content: space-evenly;
        display: flex;
        flex-flow: column;
        align-items: center;
        height: 85%;
        width: 46%;
        background-color: #ffffff;
        // padding-top: 3em;

        @media (max-width: 640px){
            width: 80%;
        }
    }

    .login-data {
        width: 70%;
        font-family: "DBHeavent";
        font-weight: bold;
        color: #646464;
        font-size: 12px;
        position: relative;

        .change-password {
            position: absolute;
            bottom: -4;
            right: 0;
            color: $AISPrimaryMain;
            cursor: pointer;
        }
    }

    .form-login {
        height: 100%;
        display: flex;
    }

    .font-size-10 {
        font-size: 10px;
    }

    .btn-login {
        width: 8em;
        height: 2.5em;
        background-color: $AISPrimaryMain;
        border: none;
        color: $defaultTextPrimary;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .form-control-1 {
        display: block;
        width: 100%;
        height: calc(2em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: $body2;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #f9f9f9;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 10px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);

        &.show-password {
            background-image: url(../images/icons/eye.svg) !important;
            background-position: 95% 50%;
            background-repeat: no-repeat;
        }
    }

    .form-control-1::placeholder {
        font-size: $body2;
    }

    .password-input {
        display: block;
        width: 100%;
        height: calc(2em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: $body2;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #f9f9f9;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 10px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .password-input::placeholder {
        font-size: $body2;
    }


    .icon-show-password {
        background-image: url('../images/icons/eye.svg');
        background-repeat: no-repeat;
        background-position:center center ;
        float:left;
        width:30px;
        height:18px;
        position: absolute;
        top: 40%;
        right: 1em;
    }
    .show-password-ready {
        background-image: url('../images/icons/ais-eye-ready.svg');
        background-repeat: no-repeat;
        background-position:center center ;
        float:left;
        width:30px;
        height:18px;
        position: absolute;
        top: 40%;
        right: 1em;
    }
    .show-password-already {
        background-image: url('../images/icons/ais-eye-already.svg');
        background-repeat: no-repeat;
        background-position:center center ;
        float:left;
        width:30px;
        height:18px;
        position: absolute;
        top: 40%;
        right: 1em;
    }

    .show-password-icon {
        position: absolute;
        top: 50%;
        left: 90%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill::first-line,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        //   border: 1px solid green;
        //   -webkit-text-fill-color: green;
        //   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: background-color 5000s ease-in-out 0s;
        animation: clearAutofill forwards;
        @include primary-font;
        font-size: $body2;
        // @extend .form-control-1.show-password;
    }

    

    .circle {
        content: "";
        width: 20rem;
        height: 20rem;
        background-color: #ED1072;
        border-radius: 50%;
        position: absolute;
        left: -5rem;
        top: -4rem;
        opacity: 0.2;
    }
    .circle-2 {
        content: "";
        width: 20rem;
        height: 20rem;
        background-color: #ED1072;
        border-radius: 50%;
        position: absolute;
        right: -5rem;
        bottom: -6rem;
        opacity: 0.2;
      }
      .border-bottom{
        position: absolute;
        width: 9px;
        height: 10px;
        background-color: orange;
        bottom: 0;
        width: 100%;
        border-radius: 7px 7px 0 0;
      }
}
