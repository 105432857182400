@import "./variables";

.order-report {
    section {
        background-color: $White;
        padding: 1em;
        margin-top: 1em;
    }

    .filter-box {
        height: 68px;
        // width: 1134px;
        width: 100%;
        background-color: white;
        margin-bottom: 20px;
        display: flex;
        flex: row wrap;
        align-items: center;
        gap: 20px;
        padding: 1em;
        justify-content: space-between;

        .filter-container {
            width: 75%;
            display: flex;
            gap: 30px;
        }

        #input-select {
            flex-grow: 1;
            margin-left: 0.5em;
        }
        
        .ant-select-selection-item {
            padding: 0 !important;
            display: flex;
            align-items: center;
        }
    }

    .table-order-report {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 20% 15% 15% 15%;
        border-radius: 10px 10px 0px 0px;
        background: $defaultBMain;
        color: $White;
        padding: 1em;
        margin-top: 1em;
        text-align: center;
        word-break: break-word;
        font-size: $body2;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
        }
        div span {
            padding: 0;
        }
    }
    .table-order-report-content {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 20% 15% 15% 15%;
        //    border-radius: 10px 10px 0px 0px;
        background: $defaultBg;
        color: $defaultTextPrimary;
        padding: 1em;
        // margin-bottom: 2em;
        //    margin-top: 1em;
        text-align: center;
        word-break: break-word;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
            background-color: $White;
            height: -webkit-fill-available;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        div span {
            padding: 0;
        }
    }

    .table-product-cart {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 10% 10%;
        border-radius: 10px 10px 0px 0px;
        background: $defaultBMain;
        color: $White;
        padding: 1em;
        text-align: center;
        word-break: break-word;
        font-size: $body2;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
        }
        div span {
            padding: 0;
        }
    }
    .table-product-cart-content {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 10% 10%;
        //    border-radius: 10px 10px 0px 0px;
        background: $defaultBg;
        color: $defaultTextPrimary;
        padding: 1em;
        text-align: center;
        word-break: break-word;
        // margin-bottom: 2em;
        align-items: center;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
            background-color: $White;
            height: -webkit-fill-available;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        div span {
            padding: 0;
        }

        .show-item {
            gap: 10px;
        }
    }

    .icon-download {
        path {
            fill: $AISPrimaryMain;
        }
    }

    .ant-select-selection-item {
        height: -webkit-fill-available;

        img {
            height: 30px;
        }
    }


    .btn-cancel{
        width: max-content;
    }

    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }

}
