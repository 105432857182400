@import "./../../styles/variables";

.modal-wrap-announce {
    .modal-box-announce {
        // background-color: pink;
        background: $lightGray;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: calc(100vh - 10vh);
        width: 60vw;
        padding: 0 !important;

        @media (max-width: 767px) {
            height: 80vh;
            width: 80vw;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
            width: 70vw;
        }

        .modal-header-announce {
            background: $White;
            padding: 1rem;
            height: 60px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-icon-close{
              @include reset-btn-default;
            }
        }

        .modal-body-announce {
            background: $lightGray;
            padding: 1rem;

            height: calc(100vh - 10vh - 60px);
            overflow-y: scroll;

            @media (max-width: 767px) {
                height: calc(80vh - 60px);
            }
            .body-announce {
                padding: 1rem;
                background: $White;
                min-height: calc(100vh - 10vh - 60px - 2rem);
                @media (max-width: 767px) {
                    min-height: calc(80vh - 60px - 2rem);
                }

                img {
                    width: 100%;
                    height: auto;
                }

                .body-box-title {
                    padding: 1rem 0;
                    h1 {
                        @include primary-font;
                        color: $defaultTextPrimary;
                        font-size: 28px;
                        font-weight: 700;
                        word-wrap: break-word;
                        margin: 0;
                    }

                    h2 {
                        @include primary-font;
                        color: $defaultTextTertiary;
                        text-align: left;
                        font-size: 16px;
                        font-weight: 400;
                        margin: 0;
                    }
                }

                .body-box-vysiwys{
                * {
                        margin: 0;
                    }
                }

            }
        }
    }
}
