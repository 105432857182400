@import './variables';

.div-side-bar-close {
  height: 87vh;
  // border: 1px solid $Grey;
  position: relative;
  margin-left: 15px;
  // background-color: #ffffff;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;

  .btn-close {
    margin: 0;
    box-shadow: none;
    min-width: auto;
    width: auto;
    height: auto;
    position: relative;
    top: 0;
    right: 0;
    background-color: #ffffff;
    border: 1px solid $Grey;
    i {
      font-size: 1.75rem;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.div-side-bar-open {
  height: 87vh;
  border: 1px solid $Grey;
  position: relative;
  margin-left: 15px;
  background-color: #ffffff;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  min-width: 23rem;
  max-width: 23rem;

  .btn-open {
    &.btn {
      box-shadow: none;
      min-width: auto;
      width: auto;
    }
    position: absolute;
    background-color: white;
    top: 0;
    right: 0;
    i {
      font-size: 1.75rem;
    }
    i::before {
      transform: rotate(180deg);
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
.wrap-side-bar::-webkit-scrollbar {
  width: 0.4em;
}
.wrap-side-bar::-webkit-scrollbar,
.wrap-side-bar::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}
.wrap-side-bar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
.cover-bar {
  position: absolute;
  background: #fff;
  height: 100%;
  top: 0;
  right: 0;
  width: 0.4em;
  border-radius: 5px !important;
  -webkit-transition: all 0.5s;
  opacity: 1;
}

.wrap-side-bar:hover .cover-bar {
  opacity: 0;
  -webkit-transition: all 0.5s;
}
.wrap-side-bar {
  background-color: #ffffff;
  width: 100%;
  height: 86.6vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 2rem 1.5rem;
  border-radius: 5px;
  .wrap-header {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid $Grey;
    .wrap-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid $Grey;
    }
    a {
      cursor: pointer;
      &.btn-address {
        color: $primaryColor;
        font-size: 1.1rem;
        padding-bottom: 1rem;
      }
    }
    .row-center {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 0.5rem;
    }
    .box-avatar {
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      img {
        width: 9rem;
        height: 9rem;
        border-radius: 50%;
      }
    }
    .name {
      font-size: 1.25rem;
      color: $Black;
      font-weight: bold;
      text-align: center;
    }
    .view-history {
      width: max-content;
    }
    .wrap-info {
      display: flex;
      flex-direction: column;
      .haeder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
        // padding-bottom: 0.75rem;
        .info-haeder {
          font-size: 1.1rem;
          color: $Black;
          margin-bottom: 0;
        }

        .btn-edit {
          &.btn {
            border: none;
            box-shadow: none;
            min-width: auto;
            width: auto;
            padding: 0 !important;
            margin: 0;
          }

          .icon-edit {
            color: $primaryColor;
            font-size: 1.75rem;
          }
        }
      }
      .info {
        font-size: 1.1rem;
        color: $Black;
        margin-bottom: 0.75rem;
        font-weight: bold;
      }
    }
  }
  .wrap-setting {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $Grey;
    padding: 1rem 0;
    .row-setting {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .text-setting {
        font-size: 1.1rem;
        color: $Black;
        margin-bottom: 0;
      }

      .btn-primary-sr {
        color: #ffffff !important;
        background-color: #e1453c;
        border-color: #e1453c;
        border-radius: 5px;
      }
    }
    .form-group {
      margin-top: 1rem;
      margin-bottom: 0;
      .form-control {
        background-color: $Background;
        border-color: $Background;
      }
    }
  }
}
