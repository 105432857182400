@import "../styles/variables";

#ribbon {
    height: 92vh;
    overflow: hidden;
    // overflow: auto;
    .content {
        display: flex;
        flex-flow: row;
        height: 83%;
        overflow: hidden;
        padding: 0.5em;
        background-color: #f3f3f3;
        font-size: $caption;
    }

    .border-white {
        background: $White;
        // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .nav-item {
        // width: -webkit-fill-available;
        text-align: center;
        // padding: 1em;
        width: 100%;
        background: $White;
        // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        @include secondary-font;
        position: relative;
        cursor: pointer;

        &.active {
            color: $defaultInfoMain;
            height: 100%;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .tab-active {
        float: left;
        width: 100%;
        padding: 3px 10px;
        height: 1px;
        position: absolute;
        background: $defaultInfoMain;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        bottom: 0;
        left: 0;
    }

    .nav-ribbon {
        width: 25%;
        position: relative;
    }
    .table-ribbon {
        width: 75%;
        position: relative;
        // padding: 0 1em;
        // background-color: $lightGray;
        // border: 1px solid #bfbfbf;
        box-sizing: border-box;
        // height: 95%;
        overflow: auto;
    }

    .detail-ribbon-table {
        font-weight: 100;
        border-radius: 10px;
        // height: 100%;
    }

    .nav-categories {
        position: relative;
        height: 78%;
        overflow: auto;
        div {
            // width: -webkit-fill-available;
            // padding: 1.5em;
            // border: 1px solid $disableGray;
            // cursor: pointer;
        }

        .nav-cat-item {
            padding: 1.5em;
            position: relative;
            cursor: pointer;
            // font-weight: bold;

            &.active {
                background-color: $lightGray;
                color: $defaultInfoMain;
                border: 1px solid $bg2;
                font-weight: bold;
            }
        }
    }

    .tab-active-vertical {
        float: left;
        /* width: 100%; */
        padding: 0 3px;
        height: 100%;
        position: absolute;
        background: #ED1072;
        /* -webkit-border-top-left-radius: 5px; */
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        /* border-top-left-radius: 10px; */
        border-top-right-radius: 10px;
        /* bottom: 0; */
        /* left: 0; */
        top: 0;
        left: 0;
        border-bottom-right-radius: 10px;
    }

    .text-limit {
        @include primary-font;
        font-size: $tooltip;
        color: $grey;
        font-weight: 100;
    }

    .cursor-add {
        cursor: url("../images/icons/icon-add.svg"), auto;
    }

    .border-disabled {
        padding: 1em 0px;
        border: 1px solid red;
        background-color: $lightRed;
        font-weight: bold;
        color: $error;
    }

    .add-image {
        color: orange;
        background-color: #fff9f3;
        padding: 2px 8px;
        border-radius: 11px;

        svg {
            width: 2em;
        }
    }

    .super-ribbon-container {
        border: 1px solid #88bb1b;
        padding: 1em;
        border-radius: 10px;
        background-color: $lightGray;
        margin: 0 1em;
        margin-bottom: 1em;
        margin-right: 0;
    }

    .list-ribbon-container {
        padding: 1em;
        background-color: #f3f3f3;
        height: 100%;
    }
}
