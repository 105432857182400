@import './variables';

#withdraw-container {
    overflow: hidden;
    @include primary-font;
    font-weight: 100;
    @media screen and (max-width: 1200px){
        width: 50em;
        overflow-x: auto;
    }

    .nav-tabs {
        overflow: hidden;
        .nav-item {
            border-radius: 10px 10px 0 0;
            color: red;
            // background-color: #383633;
            background-color: #9d9d9d;
            border: 0;
            width: 6em;
            height: 3em;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 2px;
    
            a {
                color: #ffffff;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .nav-tabs .nav-link:hover {
        border: 0;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        border: 0px solid #fff !important;
    }
    
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: #fff !important;
        background-color: #383633 !important;
        border: 0;
        border-radius: 10px 10px 0 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .input-container {
        width: 100%;
        padding: 1em 50% 1em 1em;
        background-color: $White;
        margin-bottom: 20px;
    }

    .detail-withdraw{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $White;
        padding: 2em 1em;
        position: relative;
        width: 100%;
    }

    .text-detail-withdraw {
        display: flex;
        flex-direction: column;
        // gap: 15px;
    }

    .detail-header-withdraw{
        color: $defaultTextInput;
    }

    .table-header-withdraw {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 2% auto 3% 3% 3% repeat(12,4.6%) 3% 4.6% 4.6% 3% 3% 3% 3%;
        background: $defaultBMain;
        border-radius: 10px 10px 0px 0px;
        color: $White;
        // padding: 1em;
        margin-top: 1em;
        text-align: center;
        word-break: break-word;
        width: 350%;
        // overflow: auto;
        span {
            padding: 10px;
        }
        div {
            padding: 15px;
            border-left: 1px solid $White;
        }
        div span {
            padding: 0;
        }
        .price {
            text-align: end;
        }
    }

    // .table-header-withdraw::-webkit-scrollbar {
    //     display: none;
    //   }
      
    //   /* Hide scrollbar for IE, Edge and Firefox */
    //   .table-header-withdraw {
    //     -ms-overflow-style: none;  /* IE and Edge */
    //     scrollbar-width: none;  /* Firefox */
    //   }

    .table-content-withdraw {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 2% auto 3% 3% 3% repeat(12,4.6%) 3% 4.6% 4.6% 3% 3% 3% 3%;
        background: $White;
        // border-radius: 0px 0px 10px 10px;
        color: $defaultTextPrimary;
        // padding: 1em;
        // margin-top: 1em;
        text-align: center;
        word-break: break-word;
        width: 350%;
        span {
            padding: 10px;
        }
        div {
            padding: 20px;
            // width: max-content;
            line-height: 20px;
        }
        div span {
            padding: 0;
        }
        .price {
            text-align: end;
        }
    }
    .table-content-withdraw:nth-child(even){
        background-color: $defaultSuccessBG;
    }
    .table-content-withdraw:nth-child(odd){
        background-color: $White;
    }

    .table-last-content-withdraw {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 2% auto 3% 3% 3% repeat(12,4.6%) 3% 4.6% 4.6% 3% 3% 3% 3%;
        background: $darkBlack;
        // border-radius: 0px 0px 10px 10px;
        color: $White;
        // padding: 1em;
        // margin-top: 1em;
        text-align: center;
        word-break: break-word;
        width: 350%;
        span {
            padding: 10px;
        }
        div {
            padding: 20px;
            // width: max-content;
            line-height: 20px;
            border: 1px solid $White;
        }
        div span {
            padding: 0;
        }
        .price {
            text-align: end;
        }
    }

    .table-account-header-withdraw {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 4% auto 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6%;
        background: $darkBlack;
        // border-radius: 0px 0px 10px 10px;
        color: $White;
        // padding: 1em;
        margin-top: 1em;
        text-align: center;
        word-break: break-word;
        width: 200%;
        span {
            padding: 10px;
        }
        div {
            padding: 20px;
            // width: max-content;
            line-height: 20px;
            border: 1px solid $White;
        }
        div span {
            padding: 0;
        }
        .price {
            text-align: end;
        }
    }

    .table-account-content-withdraw {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 4% auto 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6%;
        background: $White;
        // border-radius: 0px 0px 10px 10px;
        color: $defaultTextPrimary;
        // padding: 1em;
        // margin-top: 1em;
        text-align: center;
        word-break: break-word;
        width: 200%;
        span {
            padding: 10px;
        }
        div {
            padding: 20px;
            // width: max-content;
            line-height: 20px;
        }
        div span {
            padding: 0;
        }
        .price {
            text-align: end;
        }
    }
    .table-account-content-withdraw:nth-child(even){
        background-color: $defaultSuccessBG;
    }
    .table-account-content-withdraw:nth-child(odd){
        background-color: $White;
    }

    .table-account-last-content-withdraw {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 4% auto 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6%;
        background: $darkBlack;
        // border-radius: 0px 0px 10px 10px;
        color: $White;
        // padding: 1em;
        // margin-top: 1em;
        text-align: center;
        word-break: break-word;
        width: 200%;
        span {
            padding: 10px;
        }
        div {
            padding: 20px;
            // width: max-content;
            line-height: 20px;
            border: 1px solid $White;
        }
        div span {
            padding: 0;
        }
        .price {
            text-align: end;
        }
    }
}