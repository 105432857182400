@import "./playlistMallList";

.card-shop {
  padding: 13px;
  background-color: #fff;
  width: 19%;
  margin: 0 4px 8px 4px;
  box-shadow: 4px 4px 10px rgba(41, 45, 47, 0.1),
    -4px 4px 10px rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  cursor: pointer;
  & a {
    color: #383633;
    font-weight: 300;
  }
}

#scrollpane {
  height: calc(100vh - 182px);
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

#ListMall {
    height: 100vh;
    overflow: auto;
}

.text-overflow {
  display: block; /* or in-line block according to your requirement */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.shop-disabled {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
}
