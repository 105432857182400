.product-item {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  border-radius: 5px;

  .product-item-container {
    display: grid;
    grid-template-columns: 60px auto 15% 10% 10% 5%;
    padding-bottom: 16px;
    border-bottom: 1px solid #e9e9e9;

    * {
      display: flex;
      align-items: center;
    }

    .product-item-image {
      img {
        width: 48px;
        border-radius: 5px;
      }
    }

    .product-item-price{
      justify-content: flex-end;
      // margin-right: 56px;
    }

    .product-item-remains{
      justify-content: flex-end;
      // margin-right: 42px;
    }

    .product-item-status {
      justify-content: center;

      span {
        padding: 4px 8px;
        border-radius: 8px;
        background: var(--theme-primary-bg, #f9fbef);
        color: #b2d234;
      }

      span.not-show {
        background: var(--default-action-divider, #e9e9e9);
        color: #444450;
      }
    }

    .product-item-delete{
      text-align: center;

      &:hover{
        cursor: pointer;
      }
    }
  }



  .text-sku{
    margin-left: 16px;
  }



  .sku-item-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .sku-item {
      display: grid;
      grid-template-columns: 60px auto 15% 10% 10% 5%;
      border-radius: 5px;
      padding: 8px 0;
      background-color: #f9f9f9;

      * {
        display: flex;
        align-items: center;
      }

      .sku-item-checkbox {
        grid-column: 1 / 2;
        position: relative;
        
        .input-checkbox .checkmark {
          top: 50%;
          left: 60%;
          transform: translate(-50%, -50%);
        }

        .input-checkbox .container input:checked ~ .checkmark {
          background-color: #b2d235;
        }
  
        .input-checkbox .checkmark {
            border: 1px solid #b2d235;
        }

        span.checkmark.disable {
          background-color: #cccccc !important;
        }

      }

      .sku-item-detail {
        grid-column: 2 / 3;

        img {
          width: 48px;
          border-radius: 5px;
          margin-right: 5px;
        }
      }

      .sku-item-price {
        grid-column: 3 / 4;
        justify-content: flex-end;
        // margin-right: 56px;
      }

      .sku-item-amount {
        grid-column: 4 / 5;
        justify-content: flex-end;
        // margin-right: 42px;
      }

      .sku-item-status {
        grid-column: 5 / 6;
        justify-content: center;

        span {
          padding: 4px 8px;
          border-radius: 8px;
          background: var(--theme-primary-bg, #f9fbef);
          color: #b2d234;
        }

        span.not-show {
          background: var(--default-action-divider, #e9e9e9);
          color: #444450;
        }
      }
    }
  }
}
