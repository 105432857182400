
@import './variables';

#promocode{
    margin-top: 1em;
    @media screen and (max-width: 1000px){
        width: 50em;
        overflow-x: auto;
    }

    .nav-tabs {
        border-bottom: 0
    }
    .nav-item {
        width: 30%;
        text-align: center;
        background-color: $disableGray;
        height: 48px;
        border-radius: 5px 5px 0 0;
        .nav-link {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    div ul li:not(first-child){
        margin-left: 0.5em;
    }
    div ul li:first-child{
        margin-left: 0;
    }
    .active {
        color: $defaultInfoMain;
        height: 100%;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // a {
    //     // color: $grey;
    // }
    // a:active {
    //     color: $defaultInfoMain;
    // }

    .tab-select-status-promotion {
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0;
        @include primary-font;
        font-weight: 100;
        ul {
            margin-bottom: 0;
        }
        li {
            cursor: pointer;
            list-style: none;
            padding: 1em;
            margin-bottom: 0;
            height: 100%;
            position: relative;
           
        }

        li .active {
            color: $defaultInfoMain;
        }
    }

    .tab-status-active {
        float: left;
        width: 100%;
        padding: 3px 10px;
        height: 1px;
        position: absolute;
        background: $defaultInfoMain;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        bottom: 0;
        left: 0;
    }

    .search-promotion-box {
        padding: 2em 1em 0em 1em;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
        @include primary-font;
    }

    .table-list .table-list-image-preview:first-child {
        margin-top: 0;
    }

    .table-list .table-list-image-preview:not(first-child){
        margin-top: 1em;
    }

    .search-box {
        width: 40%;
    }

    .grid-col {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        // grid-template-rows: 2em 2em 2em 2em 2em;
        grid-auto-rows: auto;
        // grid-template-columns: 4em 18em 7em 5em 14em repeat(3,5em) repeat(2,2em);
        // grid-template-columns: 1fr 6fr 2fr 2fr 4fr repeat(3,2fr) 3fr;
        grid-template-columns: 8% 22% 16% 20% 18% 9% 7% 7% 15%;
        font-family: "DBHeavent";
        font-weight: lighter;
    }

    .no-item-promocode{
        height: 60vh;    
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .table-promocode{
        @include primary-font;
        font-weight: 100;
    }

    // .ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
    //     // color: $defaultInfoMain;
    // }


    .table-promocode-header {
           display: grid;
           grid-row: auto;
           grid-column: auto;
           grid-auto-rows: auto;
           grid-template-columns: 8% auto 9% 9% 11% 9% 10% 8% 8% 10%;
           background: $defaultBLight;
           border-radius: 10px 10px 0px 0px;
           color: #FFFFFF;
           padding: 1em;
        //    margin-top: 1em;
           text-align: center;
           word-break: break-word;
           font-size: $caption;
           span {
               padding: 10px;
            }
            div {
                padding: 10px;
            }
            div span {
                padding: 0;
            }      
        } 
        .platform{ 
         grid-template-columns:auto 9% 9% 11% 9% minmax(8%,100px) minmax(8%,60px) 8% 8% 10% !important;
        } 
        .table-promocode-content {
            display: grid;
               grid-row: auto;
               grid-column: auto;
               grid-auto-rows: auto;
               grid-template-columns: 8% auto 9% 9% 11% 9% 10% 8% 8% 10%;
               background: white;
               border-radius: 0px 0px 0px 0px;
               color: $defaultTextPrimary;
               padding: 1em;
               margin-top: 1em;
               text-align: center;
               word-break: break-word;
               span {
                   padding: 10px;
               }
               div {
                   padding: 10px;
               }
               div span {
                   padding: 0;
               }      
            } 

        .web-format-filter{
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            align-items: center;
            padding: 1em;
            padding-right: 0;

            li {
                cursor: pointer;
                text-align: center;
                list-style: none;
                min-width: 122px;
                border-radius: 10px;
                background: $White;
                box-shadow: 4px 4px 10px rgba(41, 45, 47, 0.1), -4px 4px 10px rgba(255, 255, 255, 0.6);
                border:0;
                padding: 5px 10px;
                // width: 122px;

                &.active {
                    border: 1px solid $AISPrimaryMain;
                    color: $AISPrimaryMain;
                }

                &.in-active {
                    color: $defaultTextInput;
                }
            }
        }
  

}

#addpromocode {

    .require::after {
        content: ' * ';
        color: red
    }
    .header-promocode {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        @include primary-font;
        span:nth-child(2){
            font-weight: 100;
            margin-left: 0.5em;
        }
        h5 {
            margin: 0;
        }
    }
    .form-control{
        width: 40%;
        height: 100px !important;
    }
    .search-refno{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
   
}