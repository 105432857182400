@import "./variables";

.shop {
    @media screen and (max-width: 1000px){
        width: 50em;
        overflow-x: auto;
    }
    span.total-shop {
        font-size: 1rem;
        font-style: normal;
        font-weight: 200;
        margin-left: 1rem;
    }
    .shop-content {
        padding: 1rem;

        .search-shop {
            background-color: white;
            padding: 1rem;
        }
        .input-text-field {
            background: #F9F9F9;
            box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            border: none;
            line-height: 28px;
            width: 50%;
            position: relative;

            input[type="text"] {
                width: 100%;
                background: #F9F9F9;
                border: none;
            }

            img {
                position: absolute;
                top: 8px;
                right: 8px;
                
            }

        }

        .no-shop {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            height: 75vh;
        }

        .shop-list {
            margin: 1rem 0;

            a {
                color: gray;
            }

            .shop-logo img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }

            .col-1-atl {
                flex: 0 0 10.333333%;
                max-width: 10.333333%;
            }
            .col-3-atl {
                flex: 0 0 29.333333%;
                max-width: 29.333333%;
            }

            .align-center {
                align-items: center;
                justify-content: center;
                display: flex;
            }

            .table-header {
                background-color: $defaultBMain;
                padding: 1rem;
                color: white;
                border-radius: 6px 6px 0 0;
            }

            .table-body {
                padding: 0 1rem 1rem 1rem;
                font-family: "DBHeavent" !important;
                font-style: normal;
                font-weight: 300;

                .row {
                    background-color: $White;
                    margin-bottom: 1rem;
                    padding: 1rem 0;
                }
            }
        }
        
        
        

    }

    .pagination-top{
        display: flow-root;
        margin-bottom: 10px;
        margin-top: 1em;
    }

    .table-grid-header {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 8% auto 15% 10% 10% 10%;
        background: $defaultBMain;
        border-radius: 10px 10px 0px 0px;
        color: #FFFFFF;
        padding: 1em;
        margin-top: 1em;
        text-align: center;
        align-items: baseline;
        div {
            padding: 5px;
        }
    }
    .table-grid-content {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 8% auto 15% 10% 10% 10%;
        color: #383633;
        background: white;
        padding: 1em;
        margin-top: 1em;
        text-align: center;
        align-items: center;
        div {
            padding: 5px;
        }
        img{
            max-width: 113px;
            max-height: 45px;
            // border-radius: 50%;
        }
    }
}