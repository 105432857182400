@import "../../styles/variables";


.ant-select-selector {
    background: #ffffff !important;
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    height: 3em !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    font-size: 16px;
    line-height: 38px;
}

.ant-select-arrow {
    color: orange;
}

.ant-select-selection-placeholder {
    font-size: 16px;
    line-height: 38px !important;
    font-family: "DBHeavent" !important;
    font-weight: 100;
}

.card {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    width: 10rem;
    height: 23em;
    // overflow: hidden;
}

.card-items > div {
    margin-right: 25px;
    margin-top: 20px;
}

.arrow-select {
    // background-image: url('../../images/icons/ais-arrow-down.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 45%;
    right: 5%;
    z-index: 1;
}
.clear-key-filter {
    // background-image: url('../../images/icons/ais-arrow-down.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 20%;
    right: 8%;
    z-index: 1;
}

.img-card-container {
    width: 100%;
    overflow: hidden;
    height: 50%;
}

.search-item {
    padding: 1em 1em;
}

.select-all-item {
    /* Customize the label (the container) */
    .container {
        display: block;
        position: relative;
        padding-left: 35px !important;
        // margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 4px;
        left: 11px;
        height: 18px;
        width: 18px;
        background-color: #ffffff;
        border: 1px solid #88bb1b;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: #88BB1B;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 5px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }

    .container:hover input ~ .checkmark {
        // background-color: aquamarine;
    }
}

.modal-footer {
    .btn-submit {
        border: 1px solid $AISPrimaryMain;
        color: $defaultTextPrimary;
        background-color: $AISPrimaryMain;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        font-family: "DBHeavent" !important;
    }
}
 
.modal-header {
    border: none;
}

.modal-dialog-item{
    .modal-dialog {
        max-width: 95%;
        margin: auto;
        margin-top: 2em;
    }
    .modal-content {
        padding: 0 5em;
        height: 60em;
        font-size: 12px;
    }
    .modal-body {
        display: block;
    }
}


ul.price-container {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;
    justify-content: space-between;
    
    .product-price {
        .ant-checkbox + span {
            padding-right: 0px !important;
            padding-left: 0px !important;
        }
    }
    .danger-label {
        color: red;
    }
}

