// @import url(https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
// @import "./login";
// @import './managelive';
@import "./scrollbar";
// @import "./product";
// @font-face {
//     font-family: "Kanit";
//     font-style: normal;
//     font-weight: 400;
//     src: url("../fonts/Kanit/Kanit-Regular.ttf");
// }
// @font-face {
//     font-family: "Kanit";
//     font-style: bold;
//     font-weight: 700;
//     src: url("../fonts/Kanit/Kanit-Bold.ttf");
// }
// @font-face {
//     font-family: "Maitree";
//     font-style: normal;
//     font-weight: 400;
//     src: url("../fonts/Maitree/Maitree-Regular.ttf");
// }
// @font-face {
//     font-family: "Maitree";
//     font-style: bold;
//     font-weight: 700;
//     src: url("../fonts/Maitree/Maitree-Bold.ttf");
// }

// @font-face {
//     font-family: "Bai_Jamjuree";
//     font-style: bold;
//     font-weight: 400;
//     src: url("../fonts/Bai_Jamjuree/BaiJamjuree-Bold.ttf");
// }
// @font-face {
//     font-family: "Bai_Jamjuree";
//     font-style: normal;
//     font-weight: 100;
//     src: url("../fonts/Bai_Jamjuree/BaiJamjuree-Regular.ttf");
// }
@font-face {
    font-family: "DBHeavent";
    // font-style: normal;
    // font-weight: 100;
    src: url("../fonts/Font_CI_AIS/DB Heavent/DB-Heavent-Cond-v3.2.ttf");
    font-variant: none;
}
@font-face {
    font-family: "DBHeavent-Bold";
    font-style: bold;
    // font-weight: 100;
    src: url("../fonts/Font_CI_AIS/DB Heavent/DB-Heavent-Bd-Cond-v3.2.ttf");
    font-variant: none;
}

@import "./variables";
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "DBHeavent" !important;
    color: $Black;
    font-size: $caption;
    font-variant-ligatures: none;

    &:focus {
        outline: none !important;
    }

    & svg {
        font-size: $tooltip;
    }
}
.root-container {
    display: inline-block;
    width: 100%;
    font-variant-ligatures: none;
}
.text-line-throgh {
    text-decoration-line: line-through;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-group {
    display: flex;
    /* Customize the label (the container) */
    .container {
        display: block;
        position: relative;
        padding-left: 35px !important;
        // margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 190px;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 4px;
        left: 1px;
        height: 18px;
        width: 18px;
        background-color: #ffffff;
        border: 1px solid #88bb1b;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: #88bb1b;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 5px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }

    .container:hover input ~ .checkmark {
        // background-color: aquamarine;
    }
}

input[type="text"] {
    font-family: "DBHeavent" !important;
    font-style: normal;
    font-weight: 300;

    &:focus {
        outline: none !important;
    }
}

::placeholder {
    color: $Gray !important;
    font-size: $detail;
}

html {
    scroll-behavior: smooth;
    font-variant: none;
}
body {
    background-color: $Background !important;
    font-size: $tooltip !important;
    font-variant-ligatures: none;
    font-variant: none;
}

.root-container {
    display: inline-block;
    font-size: $caption;
    font-variant: none;
    // width: 100%;
    // height: 100vh;
}

.modal {
    font-variant: none;
}

.cursor-pointer {
    cursor: pointer;
}

.font-size-10px {
    font-size: 10px;
}
.font-size-12px {
    font-size: 12px;
}
.font-size-14px {
    font-size: 14px;
}

.font-size-16px {
    font-size: 16px;
}
.font-size-18px {
    font-size: 18px;
}
.font-size-20px {
    font-size: 20px;
}

// .font-subtitle {
//     font-size: $subTitle;
// }

.color-grey {
    color: $Darkgray !important;
}

.color-orange {
    color: $Orange !important;
}

.color-white {
    color: $White !important;
}

.color-success-main {
    color: $defaultSuccessMain !important;
}

.color-success {
    color: $success !important;
}

.font-primary {
    font-family: "DBHeavent" !important;
}

.font-secondary {
    font-family: "DBHeavent" !important;
}

.btn {
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    width: 10em;
    height: 3em;
    cursor: pointer;

    &-cancel {
        color: $AISPrimaryMain !important;
        background-color: $White !important;
        border: 1px solid $AISPrimaryMain !important;
    }

    &-submit {
        color: $defaultTextPrimary !important;
        background-color: $AISPrimaryMain !important;
    }

    &-danger {
        color: $White !important;
        background-color: $BtnRed !important;
    }

    &-secondary {
        color: $White !important;
        background-color: $Darkgray !important;
    }
    &-download {
        color: $AISPrimaryMain !important;
        background-color: $defaultTextPrimary !important;
    }

    &-disabled {
        color: $White !important;
        background-color: $disableGray !important;
    }
    &-yellow {
        color: $Black !important;
        background-color: $Yellow !important;
    }
}

.btn-cancel {
    border: 1px solid $AISPrimaryMain;
    color: $AISPrimaryMain;
    background-color: $White;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    padding: 5px 2em;
    min-width: 8em;
    // display: inline-block;
    // width: 10em;
    // height: 2em;
}
.btn-submit {
    border: 1px solid $AISPrimaryMain;
    color: $defaultTextPrimary;
    background-color: $AISPrimaryMain;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    padding: 5px 2em;
    min-width: 8em;
    // width: 10em;
    // height: 3em;
    // font-size: 20px;
}
.btn-disable {
    border: 1px solid $defaultActionDisable;
    color: $White;
    background-color: $defaultActionDisable;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    padding: 5px 2em;
    min-width: 6em;
    // width: 10em;
    // height: 3em;
    // font-size: $tooltip;
}
.btn-disable-clearing {
    border: 1px solid $defaultActionDisable;
    color: $White;
    background-color: $defaultActionDisable;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    padding: 5px 2em;
    min-width: 6em;
    // width: 10em;
    // height: 3em;
    // font-size: $tooltip;
}
.btn-red {
    border: 1px solid $RedLogo;
    color: $White;
    background-color: $RedLogo;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    padding: 5px 2em;
    min-width: 6em;
    // height: 3em;
    // font-size: $tooltip;
}

.btn-danger {
    border: 1px solid $defaultInfoMain;
    color: $White;
    background-color: $defaultInfoMain;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    padding: 5px 15px;
    height: 3em;
    font-size: $tooltip;
}

.btn-info {
    border: none;
    padding: 0.5rem 1rem;
    background-color: $Orange !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: $tooltip;
}

.btn-camera {
    border: none;
    background-color: #fdf3e8;
    color: #ed1072;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    padding: 5px 15px;
    height: 3em;
    font-size: $tooltip;
}

@media only screen and (max-width: 800px) {
    .btn-cancel {
        width: auto;
    }
    .btn-submit {
        width: auto;
    }
    .btn-disable {
        width: auto;
    }
    .btn-disable-clearing {
        width: auto;
    }
    .btn-red {
        width: auto;
    }
    .btn-danger {
        width: auto;
    }
    .btn-info {
        width: auto;
    }
    .btn-camera {
        width: auto;
    }
}

.message {
    &-success {
        text-align: center;
        border-radius: 7px;
        padding: 4px;
        color: #88bb1b;
        background-color: #f1fbdd;
    }

    &-grey {
        text-align: center;
        border-radius: 7px;
        padding: 4px;
        color: $Darkgray2;
        background-color: $disableGray;
    }
}

.badge {
    display: inline-block;
    padding: 8px !important;
    font-size: $caption !important;
    font-weight: 300 !important;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    font-family: "DBHeavent" !important;
    font-style: normal;
    font-weight: 300;

    &-green {
        background-color: $badgeGreen;
        color: $textGreen;
    }
    &-orange {
        background-color: $badgeOrange;
        color: $textOrange;
    }
    &-red {
        background-color: $badgeRed;
        color: $textRed;
    }
    &-gray {
        background-color: $badgeGray;
        color: $textGray;
    }
    &-yellow {
        background-color: $badgeYellow;
        color: $textBadgeYellow;
    }
}

.btn {
    margin: 0rem 0.5rem;
    border-radius: 10px !important;
    min-width: 160px;
    &-ignore {
        background-color: $White !important;
        color: $AISPrimaryMain !important;
        border: solid $AISPrimaryMain 1px !important;
    }
    &-confirm {
        background-color: $AISPrimaryMain !important;
        color: $defaultTextPrimary !important;
    }
}

.col-center {
    display: flex;
    align-items: center;
}

.input-text-field {
    background: $lightGray;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    border: none;
    line-height: 28px;
    width: 50%;
    position: relative;
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: $body2;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    margin: 0 1rem;

    input[type="text"] {
        width: 100%;
        background: #f9f9f9;
        border: none;
    }

    img {
        position: absolute;
        top: 8px;
        right: 8px;
    }
}

.nopadding {
    padding: 0 !important;
}
.message-grey {
    color: #646464;
    background-color: #e1e1e1;
    text-align: center;
    border-radius: 7px;
    padding: 4px;
}

.radio-btn {
    body {
        font-family: "DBHeavent";
    }
    .radio {
        position: relative;
        cursor: pointer;
        line-height: 20px;
        // font-size: 14px;
        margin: 15px;
    }
    .radio .label {
        position: relative;
        display: block;
        float: left;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border: 2px solid #c8ccd4;
        border-radius: 100%;
        -webkit-tap-highlight-color: transparent;
    }
    .radio .label:after {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #ed1072;
        transform: scale(0);
        transition: all 0.2s ease;
        opacity: 0.08;
        pointer-events: none;
    }
    .radio:hover .label:after {
        transform: scale(3.6);
    }
    input[type="radio"]:checked + .label {
        border-color: #ed1072;
    }
    input[type="radio"]:checked + .label:after {
        transform: scale(1);
        transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        opacity: 1;
    }
    .cntr {
        position: absolute;
        top: calc(50% - 10px);
        left: 0;
        width: 100%;
        text-align: center;
    }
    .hidden {
        display: none;
    }
    .credit {
        position: fixed;
        right: 20px;
        bottom: 20px;
        transition: all 0.2s ease;
        -webkit-user-select: none;
        user-select: none;
        opacity: 0.6;
    }
    .credit img {
        width: 72px;
    }
    .credit:hover {
        transform: scale(0.95);
    }
}

.display-none {
    display: none !important;
}

.visibility-hidden {
    visibility: hidden;
}

.form-control {
    background: #f9f9f9;
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
    // height: 3em;
    font-size: $caption;
}

.form-control-3em {
    height: 3em !important;
}

.toggle-green {
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #88bb1b;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #88bb1b;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.toggle-orange {
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #ed1072;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #ed1072;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #d1d1d1;
    background: #ffffff;
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    /* shared font styles */
    // font-size: 12px;
    line-height: 16px;
    height: 13em;

    /* clicking anywhere will focus the input */
    cursor: text;
}

.react-tags-text-field {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #d1d1d1;
    background: #ffffff;
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    /* shared font styles */
    // font-size: 12px;
    line-height: 16px;
    min-height: 46px;
}

.react-tags.is-focused {
    border-color: none;
}

.react-tags__selected {
    display: inline;
}

.react-tags__search {
    position: absolute;
    background-color: transparent;
    // width: 65px;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    background: #c4c4c4;
    color: #313134;
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;

    background: #fff9f3;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow:
        4px 4px 10px rgba(41, 45, 47, 0.1),
        -4px 4px 10px rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    color: #ed1072;
}

.react-tags__selected-tag:after {
    content: "\2715";
    color: #ffffff;
    margin-left: 8px;
    font-weight: bolder;
    border: 1px solid orange;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 12px;
    background-color: #ed1072;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #b1b1b1;
}

.react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    // max-width: 100%;
    // width: 100%;
}

@media screen and (min-width: 30em) {
    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }
}

.react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
    width: 100%;
}

.react-tags__search input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
}

@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.ant-select-selection-item {
    align-self: center;
}

.input-group {
    .form-control {
        border-radius: 0.313rem;
        padding-left: 2em;
        padding-right: 2em;
        text-align: center;
        color: #9d9d9d;
    }
    .input-group-prepend {
        position: absolute;
        z-index: 4;
        .input-group-text {
            font-size: $caption;
            border: 0;
            background: transparent;
            color: #9d9d9d;
            font-weight: 100;
        }
    }
    .input-group-append {
        position: absolute;
        z-index: 4;
        right: 0;
        .input-group-text {
            font-size: $caption;
            border: 0;
            background: transparent;
            color: #9d9d9d;
            font-weight: 100;
        }
    }
}

.text-field-shadow {
    background: $lightGray;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: #f9f9f9 !important;
    border: none !important;
}

////hide arrow dropdown in datalist
[list]::-webkit-calendar-picker-indicator {
    display: none;
}
////hide arrow dropdown in datalist

.text-error {
    color: $error;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.head-content {
    font-weight: 700;
    font-size: $body2;
    // line-height: 20px;
    &-primary {
        @include primary-font;
    }
    &-secondary {
        @include secondary-font;
    }
}

.content {
    font-style: normal;
    font-weight: normal;
    font-size: $body2;
    line-height: 17px;
    &-primary {
        @include primary-font;
    }
    &-secondary {
        @include secondary-font;
    }
}

.topic {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    &-primary {
        @include primary-font;
    }
    &-secondary {
        @include secondary-font;
    }
}

.text-bold {
    font-weight: 400;
}

.section-container {
    section.section {
        background: white;
        padding: 2rem 4rem;
        margin: 1rem 0;
    }

    span.require {
        color: red;
    }

    .hyper-link {
        color: $Orange;
    }

    .title {
        @extend .info-text;
        @extend .font-primary;
        font-size: 1rem;
    }
    .title-detail {
        @extend .info-text;
        @extend .font-primary;

        font-size: 0.8rem;
        color: $Darkgray;
    }
}

.title-sm {
    @extend .info-text;
    @extend .font-primary;
    font-size: 0.8rem;
}
.title-detail-sm {
    @extend .info-text;
    @extend .font-primary;

    font-size: 0.7rem;
    color: $Darkgray;
}

.require {
    // font-weight: bold;
}

.require::after {
    content: " * ";
    color: red;
}

.react-tabs__tab-list {
    li {
        margin-right: 4px;
    }
}

.border-error {
    border: solid #e40a0a 2px !important;
}

.media-container {
    width: fit-content;
    padding: 0rem 0rem 0.5rem 0.5rem;
    margin-top: 1rem;
}

input.form-control.error-field {
    border: solid #de0000 2px;
}

span.error-message {
    @extend .info-text;
    @extend .font-primary;

    // font-size: 0.75rem;
    color: #de0000;
}

.msg-error {
    bottom: -15px;
    position: absolute;
    color: $textRed;
    font-size: $note;
}

.page-content {
    background-color: $White;
    padding: 40px;
    margin-bottom: 1em;
}

.page-content {
    background-color: $White;
    padding: 40px;
    margin-bottom: 1em;
}
.text-darkgrey2 {
    color: $Darkgray2;
}

textarea.form-control.error-field {
    border: solid #de0000 2px;
}

input.form-control.error-field {
    border: solid #de0000 2px;
}

.color-info-main {
    color: $infoMain;
}

.modal-content {
    body {
        font-size: $tooltip;
    }
}

.color-default-warning-main {
    color: $defaultWarningMain;
}

.color-ais-primary-main {
    color: $AISPrimaryMain;
}

.color-ais-text-input {
    color: $defaultTextInput;
}

.color-default-action-main {
    color: $defaultActionMain;
}

.color-default-action-icon {
    color: $defaultActionMain;
}

.svg-disabled {
    path {
        fill: $defaultActionDisable;
    }
}

.icon-manage-container {
    background: #ffffff;
    box-shadow:
        4px 4px 10px rgba(41, 45, 47, 0.1),
        -4px 4px 10px rgba(255, 255, 255, 0.6);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;

    svg {
        margin-top: 7px;
    }
}

.base-table-header {
    background-color: $defaultBMain;
    font-size: $caption;
    color: $White;
}

.base-table-content {
    background-color: $White;
    font-size: $caption;
    color: $darkBlack;
}

.font-caption {
    font-size: $caption !important;
}

.ant-select-selection-search {
    input {
        font-size: $caption;
    }
}
.ant-select-selector {
    .ant-select-selection-item {
        font-size: $caption !important;
    }
    .ant-select-selector {
        .ant-select-selection-search {
            font-size: $caption;
        }
    }
}

.input-form {
    padding: 3px 10px;
    background: #f9f9f9;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25%);
    border-radius: 10px !important;
    border: 1px solid transparent;
    width: 100%;

    &::placeholder {
        font-size: $caption;
    }

    &.error-field {
        border: 2px solid red;
    }
}

.input-form::placeholder {
    font-size: $caption;
}

.input-form:focus-visible {
    outline: none;
}

.modal-body {
    font-size: $caption;
}

// textarea::placeholder {
//  font-size: $caption;
// }

.gap-1 {
    gap: 10px;
}
.gap-2 {
    gap: 20px;
}
.gap-3 {
    gap: 30px;
}
.gap-4 {
    gap: 40px;
}

.pe-none {
    pointer-events: none;
}

.container-header-page {
    padding: 0.5em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

#rc-editable-input-26 {
    font-size: 20px !important;
    label {
        font-size: 20px !important;
    }
}

#rc-editable-input-27 {
    font-size: 20px !important;
}

#rc-editable-input-28 {
    font-size: 20px !important;
}

#rc-editable-input-29 {
    font-size: 20px !important;
}

.modal-backdrop {
    width: 100% !important;
    height: 100% !important;
}

.bg-black {
    background-color: $darkBlack;
}

.cursor-default {
    cursor: default;
}

.text-underline {
    text-decoration: underline;
}

.wrap-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;

    .progress {
        border-radius: 0px;
        height: 0.75rem;

        .progress-bar {
            background-color: $AISPrimaryMain;
        }
    }
}
