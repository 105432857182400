@import '../styles/variables';

.btn-confirm-grid {
    border-radius: 5px;
    background-color: $AISPrimaryMain;
    border: none;
    padding: 5px;
    width: 80%;
}

.btn-disabled-grid {
    border-radius: 5px;
    background-color: $ActionDisabled;
    border: none;
    padding: 5px;
    width: 80%;
    color: #2A2C2D40;
}

.btn-disabled-download-excel {
    border-radius: 5px;
    background-color: $White;
    border: none;
    padding: 5px;
    width: 80%;
    color: #2A2C2D40;
}

.transfer-detail-header {
    div:first-child {
        border: none
    }
    div {
        border-left: 1px solid $defaultActionDisable;
        padding: 0 1em;
        text-align: center;
    }
}

.not-allowed {
    cursor: not-allowed;
  }