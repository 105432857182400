@import '../styles/variables';

#promotion-delivery {
    .header-promotion-container{
        // padding: 0 2em;
    }

    .header-option-promotion{
        padding: 1em;
    }

    .table-pormotion-delivery {
        @media only screen and (max-width: 1000px) {
           width: 80em;
        }
    }

    .table-promotion-header {
            display: grid;
            grid-row: auto;
            grid-column: auto;
            grid-auto-rows: auto;
            grid-template-columns: 5% 10% auto 10% 10% 8% 15% 13% 10% 11%;
            background: $defaultBMain;
            border-radius: 10px 10px 0px 0px;
            color: #FFFFFF;
            padding: 1em 0;
            margin-top: 1em;
            text-align: center;
            word-break: break-word;
            font-size: 20px;
           font-size: $caption;
           span {
               padding: 10px;
           }
           div {
               padding: 10px;
           }
           div span {
               padding: 0;
           }
       }

       .table-promotion-content {
        display: grid;
           grid-row: auto;
           grid-column: auto;
           grid-auto-rows: auto;
           grid-template-columns: 5% 10% auto 10% 10% 8% 15% 13% 10% 11%;
           background: $White;
        //    border-radius: 10px 10px 0px 0px;
           color: $defaultTextPrimary;
        //    padding: 1em;
           margin-top: 1em;
           text-align: center;
           word-break: break-word;
           align-items: center;
           span {
               padding: 10px;
           }
           div {
               padding: 10px;
           }
           div span {
               padding: 0;
           }
       }

       .no-item-promotion {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 10em;
       }
}