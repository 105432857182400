@import '../styles/variables';
.app {
    min-height:100%;
    margin:0;
    display:grid;
    grid-template-rows:50px 1fr;
    grid-template-columns:200px 1fr;
    grid-template-areas:
      "header header"
      "sidebar content";
  }

  #header {
      grid-area:header;
      position: fixed;
      width: 100%;
      height: 49px;
      z-index: 2;
      @media (max-width: 585px){
       height: 4em;
      }
  }
  #sidebar {
      grid-area:sidebar;
      margin-top: 50px;
      position: fixed;
      height:100%;
      width: 200px;
      @media (max-width: 585px){
        margin-top: 85px; 
      }
  }
  #content {
      grid-area:content;
      padding: 10px 0;
      z-index: 1;
      transition: margin-left .5s;
      background-color: $lightGray;
      height: 100%;
      min-height: 98vh;
      margin-top: 0px;
      overflow-x: auto;

    //   @media screen and (max-width: 1000px){
    //     width: 50em;
    //     overflow-x: auto;
    // }
  }

  .hide-sidebar {
      margin-left: -200px;
  }
  