.content-container {
  .row {
    display: flex;
    &-between {
      justify-content: space-between;
    }
    &-start {
      justify-content: flex-start;
    }
    &-end {
      justify-content: flex-end;
    }

    .item-wrap {
      display: block;
    }
  }

  .filter-wrapper {
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    align-items: center;
    width: 100%;

    .dropdown-wrap {
      margin-left: 4rem;
      width: 100%;

      .filter-dropdown {
        margin-left: 10px;
        margin-right: 10px;

        .ant-select-selector {
          height: 39px !important;
          background-color: #f9f9f9 !important;
          border: 1px solid #e1e1e1 !important;
          border-radius: 5px !important;
        }

        .icon-angle {
          color: #ED1072;
          font-size: 0.875rem;
        }
      }

      .year-filter-picker {
        width: 22%;
        box-shadow: unset;
        border-radius: 5px !important;
        background-color: #f9f9f9;
        border: 1px solid #e1e1e1;

        .icon-angle {
          color: #ED1072;
        } 

        .ant-picker-input > input {
          color: #000 !important;
        }
      }
    }
  }

  .content-wrapper {
    margin-top: 0.75rem;

    .header-wrap {
      background-color: #383633;
      color: #fff;
      padding: 20px;
      border-radius: 10px 10px 0 0;

      .email-wrapper {
        display: flex;
        justify-content: space-around;
      }
    }

    .tabs-broadcast {
      .ant-tabs-tab {
        background-color: #9d9d9d;
        padding: 11px 27px 11px;
        border-radius: 5px 5px 0 0;
      }

      .ant-tabs-tab-active {
        background-color: #383633;
        padding: 11px 27px 11px;
        border-radius: 5px 5px 0 0;
      }

      .ant-tabs-tab-btn {
        color: #fff;
        font-weight: 500;
      }

      .not-found-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 11rem;

        .text-not-found {
          font-size: 1.25rem;
          color: #646464;
          margin-top: 0.5rem;
        }
      }
    }

    .ant-card-body {
      .name {
        font-weight: bold;
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .description {
        font-size: 0.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon-wrapper {
        box-shadow: 4px 4px 10px rgba(41, 45, 47, 0.1), -4px 4px 10px rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ED1072;
      }
    }
  }
}

.ant-picker-content {
  border: unset;
}

.ant-picker-content th,
.ant-picker-content td {
  border: unset;
  width: auto;
  overflow: auto;
}
