@import './variables';

#mass-upload-excel-edit {
    .bg-white {
        background-color: white;
        padding: 1em;
        margin-bottom: 0.5em;
    }

    .text-danger {
        svg {
            path {
                fill : #dc3545;
            }
        }
    }

    .text-download {
        &:hover{
            text-decoration: underline;
        }
    }

    .icon-mass-product-edit {
        height: 3em;
        display: flex;
        align-items: center;
        gap: 7px;
        justify-content: center;
        padding: 0;
    }

    .table-mass-upload-header {
        display: grid;
           grid-row: auto;
           grid-column: auto;
           grid-auto-rows: auto;
           background: $defaultBMain;
           border-radius: 10px 10px 0px 0px;
           color: #FFFFFF;
           padding: 1em;
           margin-top: 1em;
           text-align: center;
           word-break: break-word;
           span {
               padding: 10px;
           }
           div {
               padding: 10px;
           }
           div span {
               padding: 0;
           }
       }

       .width-table-mass-upload {
        grid-template-columns: 10% 13% auto 12% 12% 12% 12%;
       }

       .table-mass-upload-content {
        display: grid;
           grid-row: auto;
           grid-column: auto;
           grid-auto-rows: auto;
           background: white;
           border-radius: 10px 10px 0px 0px;
           color: $defaultTextPrimary;
           padding: 1em;
           margin-top: 1em;
           text-align: center;
           word-break: break-word;
           span {
               padding: 10px;
           }
           div {
               padding: 10px;
           }
           div span {
               padding: 0;
           }
       }

       svg {
           path {
               fill: $AISPrimaryMain;
           }
       }

       .icon-hint {
           svg {
               path {
                   fill: $defaultErrorMain;
               }
           }
           color: $defaultErrorMain;
       }

       .color-upload-status-success {
            color: $defaultSuccessMain;
       }

       .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
        padding: 10px;
      }
      
      /* Tooltip text */
      .tooltip-mass-upload .tooltiptext {
        visibility: hidden;
        width: 154px;
        background-color: $defaultWarningMain;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        padding: 10px !important;
        // top: -6em;
        bottom: 2em;
        left: -38px;
       
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
      }
      
      /* Show the tooltip text when you mouse over the tooltip container */
      .tooltip-mass-upload:hover .tooltiptext {
        visibility: visible;
      }
    
      .tooltip-mass-upload .tooltiptext::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 30%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $defaultWarningMain transparent transparent transparent;
      }
    
      .tooltip-mass-upload span .tooltiptext {
        margin-left: -82px;
        margin-top: -62px;
    }
}


