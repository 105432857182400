@import "../styles/variables";

.web-hook-container {
    padding-right: 15px;
    padding-left: 15px;
    .btn-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 5px;
        margin-left: 10px;
    }
    .btn {
        display: flex;
        width: 80px;
        height: 32px;
        padding: 0px 9px;
        justify-content: center;
        align-items: center;
        min-width: 80px;
    }
}
