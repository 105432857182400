@import "../../styles/variables";

.wrap-card-shop {
  padding: 8px;
  background-color: $White;
  display: grid;
  grid-template-columns: 42px auto auto;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  box-shadow: -4px 4px 10px 0px rgba(255, 255, 255, 0.6),
    4px 4px 10px 0px rgba(41, 45, 47, 0.1);
  border: none;
  cursor: pointer;
  min-height: 60px;
  margin-right: 6px;

  &.isActive {
    outline: 2px solid #ed1072;
  }

  .cover-shop-logo {
    img {
      width: 42px;
      // object-fit: contain;
      border-radius: 5px;
      height: 31px;
    }
  }

  .cover-shop-name {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .shop-name {
      font-size: 18px;
      color: $defaultBMain;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      word-break: break-word;
    }
  }

  .cover-badge {
    background-color: #ec008c;
    color: $White;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    width: fit-content;
    margin-right: 0;
    margin-left: auto;
    max-height: 28px;
    font-size: 14px;
  }
}
