@import "./variables";

.ant-select .ant-select-single .ant-select-show-arrow {
    .ant-select-arrow {
        color: orange !important;
    }
}

.ml-10per {
    margin-left: 10%;
}

.bg-white {
    background-color: #ffffff;
}

.first-column {
    width: 12em;
    max-width: 12em;
}

.form-check-label {
    @extend .info-text;
    @extend .font-primary;
}

.seach-shop {
    background-color: #ffffff;
    padding: 1em;
    display: flex;
    flex-flow: row;
    align-items: center;
}

.add-product-container {
    span {
        font-family: "DBHeavent";
        font-weight: lighter;
        text-align: left;
    }

    .seach-shop {
        background-color: #ffffff;
        padding: 1em;
    }

    &.add-product-container > div {
        margin: 1.5em 0;
    }

    .option-detail {
        input[type="text"] {
            height: 47px;
        }
    }

    .form-control {
        font-size: $caption;
        &::placeholder {
            font-size: $caption;
        }
    }
}

.radio-btn {
    body {
        font-family: "DBHeavent";
    }
    .radio {
        position: relative;
        cursor: pointer;
        line-height: 20px;
        // font-size: 14px;
        margin: 15px;
    }
    .radio .label {
        position: relative;
        display: block;
        float: left;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border: 2px solid #c8ccd4;
        border-radius: 100%;
        -webkit-tap-highlight-color: transparent;
    }
    .radio .label:after {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #ED1072;
        transform: scale(0);
        transition: all 0.2s ease;
        opacity: 0.08;
        pointer-events: none;
    }
    .radio:hover .label:after {
        transform: scale(3.6);
    }
    input[type="radio"]:checked + .label {
        border-color: #ED1072;
    }
    input[type="radio"]:checked + .label:after {
        transform: scale(1);
        transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        opacity: 1;
    }
    
    // input[type="radio"]:disabled + .label {
    //     border-color: #ED1072;
    //     opacity: 0.2;
    // }

    input[type="radio"]:disabled + .label {
        border-color: #c8ccd4;
        opacity: 0.4;
    }
    // input[type="radio"]:disabled + .label:after {
    //     transform: scale(1);
    //     transition: all 0.2s black;
    //     opacity: 0.5;
    // }
    .cntr {
        position: absolute;
        top: calc(50% - 10px);
        left: 0;
        width: 100%;
        text-align: center;
    }
    .hidden {
        display: none;
    }
    .credit {
        position: fixed;
        right: 20px;
        bottom: 20px;
        transition: all 0.2s ease;
        -webkit-user-select: none;
        user-select: none;
        opacity: 0.6;
    }
    .credit img {
        width: 72px;
    }
    .credit:hover {
        transform: scale(0.95);
    }
}

.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    background-color: #ffffff;
}

.rdw-editor-wrapper {
    border: 0px solid black;
    background: #f9f9f9;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1em;
    .rdw-editor-main {
        height: 15em;
        overflow: auto;
        box-sizing: border-box;
        background-color: #ffffff;
    }
    .rdw-editor-toolbar {
        padding: 6px 5px 0;
        border-radius: 2px;
        border: none;
        display: flex;
        justify-content: flex-start;
        background: white;
        flex-wrap: wrap;
        font-size: 15px;
        margin-bottom: 5px;
        -webkit-user-select: none;
        user-select: none;
        background-color: #f9f9f9;
    }
}
.detail-product {
}

// .col-1,
// .col-2,
// .col-3,
// .col-4,
// .col-5,
// .col-6,
// .col-7,
// .col-8,
// .col-9,
// .col-10,
// .col-11,
// .col12 {
//     padding: 0 !important;
// }

// .row-input {
//     padding: 0 0.5em;
// }

.row-input > div {
    padding: 0 0.5em;
}

.row-input > div:last-child {
    // padding: 0 0.5em;
    padding-right: 0;
}

.mt-4em {
    margin-top: 4em;
}

.options-product {
    padding: 2em 0;
    border-bottom: 1px solid #eeeeee;
}

.seo-manage {
}

.seo-manage > div {
    margin-top: 1em;
}

.add-product-select {
    width: 100%;
}

label {
    margin-bottom: 0 !important;
}

.edit-profile-seo {
    bottom: -15px;
    right: -15px;
}

.preview-product-img {
    min-width: 12em;
    min-height: 12em;
    max-width: 12em;
    max-height: 12em;
    margin-right: 0.5em;
    margin-top: 0.5em;
    background-color: black;
    z-index: 1;
    position: relative;
}

.remove-image-product {
    right: 0;
    position: absolute;
    // display: none;
}

.remove-image-product:hover {
    display: inline;
}

// .hover-image-product:hover {
//     // opacity: 0.7;
//     .remove-image-product:hover {
//         display: inline;
//     }
// }

.item-product-container {
    position: relative;
    margin-right: 0.5em;
    margin-top: 0.5em;
    background-color: black;
}
  
  .image {
    opacity: 1;
    // display: block;
    min-width: 12em;
    min-height: 12em;
    max-width: 12em;
    max-height: 12em;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;

    &.cover{
        max-width: fit-content;
        max-height: fit-content;
    }
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .item-product-container:hover .image {
    opacity: 0.3;
  }
  
  .item-product-container:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }

  #list-brands {
      background-color: #F9F9F9;
    

    datalist option * {
        background-color: red;
    }
  }

.update-stock {
    display: flex;

    @extend .info-text;
    @extend .font-primary;

    &-item.active {
        background-color: #f98d11;
        color: white;
    }
    
    &-item {
        padding: 0.5rem 1rem;
        border: solid 1px #f98d11;
        cursor: pointer;
        width: 250px;
        transition: background .2s ease-in-out;
    }
}

.remaining-stock {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .label {
        color: $Darkgray;
        font-size: 1.4rem;

        .change {
            color: $Red;
        }
    }

}

.title {
    color: #646464;
    font-size: $caption !important;
    // font-weight: 600;

    &-detail {
        color: #9D9D9D;
        font-size: $tooltip;
    }
}

.product-sku-image {
    max-width: 12em;
    max-height: 12em;
    width: 12em;
    height: 12em;
    position: relative;
    display: block;
    margin-right: 6px;
    cursor: pointer;

    .image-preview {
        max-width: 12em;
        max-height: 12em;
        width: 12em;
        height: 12em;
        margin: 0.5em 0.5em 0 0;
    }

    .upload-icon {
        position: absolute;
        bottom: -20px;
        right: -10px;
        cursor: pointer;
    }


}

.input-group {
    margin-top: 4px;
}


.image-live-container .btn-upload-image-sku {
    background-color: #FDF3E8;
    border: none;
    width: 8em;
    height: 8em;
    margin: 0, 0.5rem;
    border-radius: 10px;
    color: #ED1072;
    font-family: "DBHeavent" !important;
    font-style: normal;
    font-weight: 300;
}

.product-sku-image-wrap {
    margin: 0.5rem 1rem 0 0;
    
    p {
        @extend .info-text;
        @extend .font-primary;
        color: $darkBlack;
        margin-top: 5px;
        text-align: center;
    }
}
.checkmark {
    border-radius: 2px;
}

.checkmark.disable {
    background-color: #cccccc;
    border : none !important;
}

span.disable {
    color: #ababab;
}

.rate-type {
    display: block; 
    width: 100%; 
    height: calc(1.5em + 0.75rem + 2px); 
    padding: 0.375rem 0.75rem; 
    font-size: $body2;
    font-weight: 400; 
    color:$Darkgray; 
    background-color: #fff;
    text-align: right;
    padding-right: 24px; 
    // text-align: center;
    color: #ababab;
}

.label-title {
    font-size: 0.9rem;
    font-weight: 700 !important;
}

.label-detail {
    font-size: 0.75rem;
    color: $Darkgray;
}

.is-focused{ 
    border-color: none;
}

.sortableHelper {
   z-index: 99999999 !important;
}

.input-search {
    .form-control {
        box-shadow: inset 0px 0px 1px rgba(0 ,0 ,0 ,0.25);
    }
}
