@import '../styles/variables';

.add-logistic-price {
    .container-box {
        padding: 1em;
        margin: 1em;
        background-color: $White;

        .form-control {
            &.set-price {
                width: 120px;
            }
        }
    }

    .set-price-value {
        border: 1px solid $bg3;
        padding: 1em;
        margin-top: 1em;
    }

    .opacity-disable-price {
        opacity: 0.4;
    }
}