@import "./variables";

.container-playlist-mall {
    
    .grid-col {
        display: grid;
        grid-template-columns: 10% auto 10%;
        gap: 1rem;
        padding: 1em 0.5em;
        margin-bottom: 1rem;
    }
    .table-header {
        @extend .font-secondary;
        @extend .grid-col;
        background-color: $defaultBMain;
        border-radius: 5px 5px 0 0;
        color: $White;
        
    }
    .table-row {
        @extend .font-primary;
        @extend .grid-col;
        background-color: $White;
        font-weight: 300;

        img {
            height: 45px;
            width: 110px;
            object-fit: contain;
        }
    }

    .clickable {
        cursor: pointer;
    }

}

.container-modal-add-mall-playlist {
    .ant-checkbox + span {
        padding-right: 0px; 
        padding-left: 0px; 
    }
    .grid-col {
        display: grid;
        grid-template-columns: 8% 8% auto;
        padding: 1em 0.5em;
        margin-bottom: 1rem;
    }
    .table-header {
        @extend .font-secondary;
        @extend .grid-col;
        background-color: $defaultBMain;
        border-radius: 5px 5px 0 0;
        color: $White;
        
    }
    .table-row {
        @extend .font-primary;
        @extend .grid-col;
        background-color: $White;
        font-weight: 300;

        img {
            height: 50px;
        }
    }
}
