@import './variables';

.chatRoom {
  .title {
    color: $Black;
    font-family: DBHeavent;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .btn-modal {
    color: $primaryColor;
    border-color: $primaryColor;
    font-size:  24px;
    font-style: normal;
    font-weight: 400;
    width: 160px;
    height: 44px;
    padding: 4px;
    font-family: DBHeavent !important;

    &:hover {
      color: $primaryColor;
      border-color: $primaryColor;
    }
  }

  .box-message {
    background-color: $bg3;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin: 1em 0 1em 0;

    .active {
      background-color: $badgeGreen;
    }

    .time-message {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      white-space: nowrap;
    }

    .text-message {
      width: 100%;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      margin-left: 10px;
      margin-right: 10px;
    }

    .status-message {
      white-space: nowrap;
      align-self: center;
      margin-right: 10px;
      font-size: 24px;
    }
  }

  .active {
    background-color: $badgeGreen;
  }

  .div-all {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    height: 91vh;
    align-items: flex-start;
    min-width: 100%;
    justify-content: space-between;
    // padding-top: 4rem;
    background-color: $Background;
    box-sizing: border-box;
    overflow: hidden;

    &.chat-summary {
      height: 80vh;
    }
  }

  .div-list-user {
    min-width: 23rem;
    max-width: 23rem;
    height: 87vh;
    border: 1px solid $Grey;
    position: relative;
    margin-right: 15px;
    border-radius: 5px;
    transition: all .5s ease-in-out;

    // margin: 0.7rem;
    // overflow-y: scroll;
    @media (max-width:992px) {
      min-width: 20rem;
      max-width: 20rem;
    }

    @media (max-width:768px) {
      margin-right: 15px;
      min-width: 18rem;
      max-width: 18rem;
    }

  }

  .div-list-chat {
    width: 100%;
    height: 87vh;
    border: 1px solid $Lightgrey;
    position: relative;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid $Grey;
    // margin: 0.7rem;

    &.summary {
      height: 80vh;
    }
  }

  .css-y1c0xs,
  [data-css-y1c0xs] {
    padding-right: 1rem;
  }

  .css-y1c0xs::-webkit-scrollbar {
    width: .4em;
  }

  .css-y1c0xs::-webkit-scrollbar,
  .css-y1c0xs::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px;
  }

  .css-y1c0xs::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .2);
  }

  .cover-bar {
    position: absolute;
    background: #fff;
    height: 100%;
    top: 0;
    right: 0;
    width: .4em;
    -webkit-transition: all .5s;
    opacity: 1;
  }

  .css-y1c0xs:hover .cover-bar {
    opacity: 0;
    -webkit-transition: all .5s;
  }

  .haeder-bar {
    width: 100%;
    height: 3.5rem;
    // padding: 1rem;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;

    .box-logo {
      height: 2.5rem;

      img {
        height: 100%;
      }
    }

    .user {
      display: flex;
      align-items: center;

      .box-avatar {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;

        img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }

      .btn-logout {
        color: $Black;
        border: 0;

        &:focus {
          box-shadow: none !important;
          outline: none !important;
        }
      }
    }

    label {
      margin-bottom: 0;
      color: #ffffff;
      font-size: 1.25rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }


  // .modal-dialog {
  //   height: 80%;
  //   display: flex;
  //   align-items: center;


  //   @media only screen and (min-width: 700px) {
  //     width: 30em !important;
  //     max-width: 30em !important;
  //   }


  //   .modal-content {
  //     height: 30em;
  //   }

  //   .modal-body {
  //     display: flex;
  //     flex-flow: column;
  //     align-items: center;
  //     justify-content: space-around;

  //     .title {
  //       // font-size: 20px;
  //       text-align: center;
  //     }

  //     .info-message {
  //       text-align: center;
  //       font-size: 3rem;
  //     }

  //   }
  // }


}