#add-announce {
    .add-announce-container {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;

        .add-announce-detail .text-input-field > label {
            font-weight: 700;
        }
        
        .add-announce-detail {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;
            background-color: #fff;

            .H6 {
                font-weight: 700;
                border-bottom: solid #f5f5f5 1px
            }
            

            .item-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .row-container.row-image {
                >.item-container {
                    display: flex;
                    flex-direction: column;
                    gap: 0.1rem
                }
            }


            .row-container.row-description {
                >.item-container {
                    display: flex;
                    flex-direction: column;
                    gap: 0.1rem
                }
                .rdw-editor-toolbar {
                    border-bottom: 1px solid #C5CDCD;
                }
            }
        }

        .row-container.row-footer {
            .btn {
                width: 10em !important;
            }

            >.item-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 0rem;
            }
        }
    }

    .button-upload-media {
        position: relative;

        input[type='file'] {
            width: 100%;
            height: 100%;
        }
    }


    @media (min-width: 1025) {
        .add-announce-container {
            padding: 1rem !important;
        }

        .button-wrap {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }

}