@import '../styles/variables';

.create-boardcast-container {
        .broadcast-content-box {
                padding: 1em;
                background-color: $White;
        }

        .content-container {
            height: 202px;
            border: 1px dashed #BFBFBF;
            background-color: #f9f9f9;
            border-radius: 10px;
            padding: 1em;
            margin-top: 1em;
        }

        .show-content-box {
            background-color: $White;
            width: 100%;
            height: 100%;
        }

        .btn-with-icon{
            width: 25%;
            background-color: #FDF3E8;
            color: $defaultInfoMain;
            border-radius: 10px;
        }


        .navbar{
            .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
                color: #495057;
                background-color: #fff;
                border-color: #fff !important;
            }
    
            .nav-tabs .nav-link {
                border: 1px solid transparent;
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
                background-color: #eeeeee;
            }
    
            .nav-tabs {
                border-bottom: none;
            }
        }

        a {
            color:#383633;
        }
       

       
}