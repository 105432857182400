// $primaryColor: #ED1072;
$primaryColor:#ED1072;
$secondaryColor: #f7b100;
// $Background:#EEF1F4;
$Background: #ffffff;
$White: #ffffff;
$lightGray: #f9f9f9;
$Gray: #e0e0e0;
$Darkgray: #828282;
$Darkgray2: #646464;
$Black: #131414;
$darkBlack: #383633;
$Red: #e94d4d;
$Orange: #ED1072;
$Yellow: #f7ef00;
$LightBlue: #f1f3ff;
$line: #57b535;
$facebook: #1877f2;
$GreyComingSoon: #e9ecef;
$Tertiary: #31678c;
$lightYellow: #feedc3;
$lightGreen: #f1fbdd;
$oldLace: #fdf3e8;
$disableGray: #e1e1e1;
$lightRed: #ffecec;
$grey: #9d9d9d;
$DarkBlue: #333bff;
$GreyIcon: #808081;
$ActionDisabled: #dbdbdb;
$infoMain: #ED1072;

$BtnRed: #ff1010;
$RedLogo: #d10808;

$badgeGreen: #f1fbdd;
$badgeOrange: #feedc3;
$badgeRed: #ffecec;
$badgeGray: #e1e1e1;
$badgeYellow: #fff4da;

$textRed: #d10808;
$textGreen: #88bb1b;
$textOrange: #ED1072;
$textGray: #808081;
$textBadgeYellow: #ED1072;

$success: #88bb1b;
$error: #d10808;
$bg3: #eeeeee;
$bgHot: #FFF9F3;
$bg2: #f3f3f3;

$defaultText: #FFFFFF;
$defaultBg: #F9F9F9;
$defaultTextPrimary: #2A2C2D;
$defaultTextSecondary: #444450;
$defaultTextTertiary: #6B6B6B;
$defaultTextInput: #AEAFAF;
$defaultTextDisable: rgba (42,44,45, 0.20);

$defaultActionMain: #AFAEAE;
$defaultActionDisable: #DBDBDB;
$defaultActionborder: #F2F2F2;
$defaultActionDivider: #E9E9E9;

$defaultInfoMain: #ED1072;
$defaultInfoBg: #FDEBF6;

$defaultSuccessMain: #008F46;
$defaultSuccessBG: #EEF5ED;

$defaultWarningMain: #FE9138;
$defaultWarningBg: #FFF5ED;

$defaultErrorMain: #ED1E46;
$defaultErrorDark: #C81F4C;
$defaultErrorBg: #FEF5F4;

$AISPrimaryMain:#B2D234;
$AISPrimaryLight: #DCE04E;
$AISPrimaryDark: #C9D44F;
$AISPrimaryBg: #F9FBEF;
$AISPrimaryBorder:#F4F8E2;

$defaultBMain: #252527;
$defaultBDark: #141414;
$defaultBLight: #2E2E31;
// $PrimaryMain: #

$H4: 42px;
$H5: 32px;
$H6: 28px;
$subTitle1: 24px;
$subTitle2: 22px;
$caption: 20px;
$subCaption: 20px;
$tooltip: 16px;
$subTooltip: 16px;
$H1: 104px;
$H2: 68px;
$H3: 56px;
$body1: 24px;

$body2: 22px;
$content: 14px;
$note: 10px;

:root {
  font-size: 16px;
}

//unit
$unit20: 1.25rem;
$unit15: 0.938rem;
$unit10: 0.625rem;
$unit5: 0.313rem;

//font
$bigNumber: 1.625rem;
$mediumPrice: 1rem;
$topic: 1.125rem;
$title: 1rem;
$button: 1rem;
$detail: 0.938rem;
$small: 0.75rem;
$iconFont: 1.375rem;
$killedPrice: 0.75rem;

//width button
$widthButton: 8rem;

.pt-20 {
  padding-top: $unit20 !important;
}
.pb-20 {
  padding-bottom: $unit20 !important;
}
.pl-20 {
  padding-left: $unit20 !important;
}
.pr-20 {
  padding-right: $unit20 !important;
}

.pt-15 {
  padding-top: $unit15 !important;
}
.pb-15 {
  padding-bottom: $unit15 !important;
}
.pl-15 {
  padding-left: $unit15 !important;
}
.pr-15 {
  padding-right: $unit15 !important;
}

.pt-10 {
  padding-top: $unit10 !important;
}
.pb-10 {
  padding-bottom: $unit10 !important;
}
.pl-10 {
  padding-left: $unit10 !important;
}
.pr-10 {
  padding-right: $unit10 !important;
}

.pt-05 {
  padding-top: $unit5 !important;
}
.pb-05 {
  padding-bottom: $unit5 !important;
}
.pl-05 {
  padding-left: $unit5 !important;
}
.pr-05 {
  padding-right: $unit5 !important;
}
////
.mt-20 {
  margin-top: $unit20 !important;
}
.mb-20 {
  margin-bottom: $unit20 !important;
}
.ml-20 {
  margin-left: $unit20 !important;
}
.mr-20 {
  margin-right: $unit20 !important;
}

.mt-15 {
  margin-top: $unit15 !important;
}
.mb-15 {
  margin-bottom: $unit15 !important;
}
.ml-15 {
  margin-left: $unit15 !important;
}
.mr-15 {
  margin-right: $unit15 !important;
}

.mt-10 {
  margin-top: $unit10 !important;
}
.mb-10 {
  margin-bottom: $unit10 !important;
}
.ml-10 {
  margin-left: $unit10 !important;
}
.mr-10 {
  margin-right: $unit10 !important;
}

.mt-05 {
  margin-top: $unit5 !important;
}
.mb-05 {
  margin-bottom: $unit5 !important;
}
.ml-05 {
  margin-left: $unit5 !important;
}
.mr-05 {
  margin-right: $unit5 !important;
}

.font-size-10px {
  font-size: 10px;
}

.font-size-12px {
  font-size: 12px;
}

.color-grey {
  color: $Darkgray;
}

.color-primary {
  color: $primaryColor;
}

@mixin primary-font {
  font-family: "DBHeavent" !important;
}
@mixin secondary-font {
  font-family: "DBHeavent" !important;
}

@mixin icon($color, $hoverColor) {
  svg {
    path {
      fill: $color;
    }

    circle,
    line,
    path {
      fill: $color;
    }

    &:hover {
      path {
        fill: $hoverColor;
      }

      circle,
      line,
      path {
        fill: $hoverColor;
      }
    }
  }
}


@mixin reset-btn-default {
  border: 0;
  background: transparent;
  * {
    cursor: pointer;
  }
}

.font-primary {
  font-family: "DBHeavent" !important;
}

.font-secondary {
  font-family: "DBHeavent" !important;
}

.info-text {
  font-style: normal;
  font-weight: 300;
}

.title-text {
  font-weight: 400;
}

.caption-text {
  // @include primary-font;
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 15px;
}

.content-text {
  // @include primary-font;
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 17px;
}

.title-text {
  // @include primary-font;
  font-style: 100;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.subtitle-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.caption-semi-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.h-50vh {
  height: 50vh;
}

.color-success {
  color: $success !important;
}

.color-darkgrey {
  color: $Darkgray2;
}

.color-grey {
  color: $grey;
}

.color-default-success {
  color: $defaultSuccessMain;
}

.bg-light-red {
background: $lightRed;
}
