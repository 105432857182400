.card-product {
    background-color: #ffffff !important;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: var(--default-default-white, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    height: fit-content;

    .input-checkbox .container input:checked ~ .checkmark {
        background-color: #b2d235;
    }

    .input-checkbox .checkmark {
        border: 1px solid #b2d235;
    }

    .product-image {
        img {
            width: 100%;
            // height: 240px;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 5px 5px 0 0;
        }
    }

    .select-product {
        position: absolute;
        top: 7px;
        right: -7px;

        span.checkmark.disable {
            background-color: #cccccc !important;
        }
    }

    .product-detail {
        padding: 1rem 1rem 0.2rem 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product-name {
            font-size: 20px;
            font-weight: 400;
            line-height: 1.2rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 36px;
        }

        .product-price {
            font-size: 20px;
            font-weight: 700;
            color: #EC008C;
        }
    }

}

.card-product.active {
    border: none !important;
    outline: 3px solid #ed1072;
}


.list-card {
    display: grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    gap: 15px;
}