@import "./variables";

.wrap-manage-announce {
  .cover-header-announce {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title-manage-announce {
      font-size: 32px;
      color: $defaultTextPrimary;
      margin-right: 16px;
    }

    .subTitle-manage-announce {
      font-size: 24px;
      color: $defaultTextPrimary;
    }

    .cover-add-announce {
      cursor: pointer;

      .text-add-announce {
        font-size: 24px;
        color: $primaryColor;
      }

      svg {
        color: $primaryColor;
        margin-right: 8px;
      }
    }
  }

  .cover-body-announce {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .cover-pagination-announce {
      margin: 16px 0px;
    }

    .no-data-announce {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100vh - 131px);

      .text-no-data {
        font-size: 28px;
        font-weight: 700;
        color: #6B6B6B;
      }

      svg > path {
        fill: #D8C47A;
      }
    }
  }
}
