@import './variables';

//modal-medium
@media (min-width: 576px) {
  .modal-dialog.modal-medium {
    max-width: 35rem !important;
  }
}

.modal-medium {
  .modal-content {
    border-radius: 5px;
  }

  .modal-header {
    border-bottom: 0 !important;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 1.5rem 2rem 1rem 2rem;
    position: relative;
    &.reply {
      background-color: #f3f3f3 !important;
    }

    h3 {
      margin-bottom: 0;
      font-size: 1.25rem;
      color: $Black;
      font-weight: bold;
    }

    .btn-cloes {
      box-shadow: none;
      width: auto;
      height: auto;
      min-width: auto;
      position: absolute;
      right: 1rem;
      top: 0.25rem;
      padding: 0;
      margin: 0;

      i {
        font-size: 1.5rem;
        color: $Black;
      }
    }
  }

  .modal-body {
    padding: 1rem 2rem;
    // background-color: $White;
    background: url(https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif) no-repeat center;

    &.body-reply {
      padding: 0 1rem 2rem !important;
      background-color: #f3f3f3;
    }

    .box-profile {
      display: flex;
      justify-content: flex-start;
      width: -webkit-fill-available;
      align-items: center;
      margin-bottom: 1rem;

      img {
        margin-right: 1rem;
        border-radius: 50%;
      }
    }

    .box-preview {
      text-align: center;

      .box-image {
        position: relative;
        background-color: #e1e1e1;



        .img-preview {
          height: auto;
          width: auto;
          max-width: -webkit-fill-available;
          max-height: 350px;
        }
        .img-close {
          top: 0;
          right: 0;
          position: absolute;
          cursor: pointer;
        }
      }


    }

    h3 {
      margin-bottom: 0;
      font-size: 1.25rem;
      color: $Black;
      font-weight: bold;
    }

    .invalid-text {
      display: flex;
      width: 100%;
      justify-content: center;
      color: $Tagred;
      padding-top: 1rem;
    }

    .center {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    button {
      &.btn-map {
        width: 80%;
      }
    }

    &.preview {
      padding: 1rem;
    }

    .box-img {
      width: 100%;

      img {
        width: 100%;
      }

      .btn-cloes {
        &.btn {
          background-color: transparent;
          box-shadow: none;
          margin: 0.25rem 1rem;
          min-width: auto;
          width: auto;
          height: auto;
          font-size: 1.5rem;
          line-height: 1.5rem;
        }

        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        background-color: #ffffff;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        i {
          font-size: 1.5rem;
          color: $Black;
        }
      }
    }

    .text-haeder {
      font-size: 1.2rem;
      color: $Black;
      margin-bottom: 0;
    }

    .form-control {
      border-radius: 5px;
      background-color: $Background;
    }

    .valid {
      border: 1px solid $secondaryColor !important;
    }

    .invalid {
      border: 1px solid $Tagred !important;
    }

    .form-group {
      label {
        color: $Black;
        font-size: 1rem;

        span {
          color: #eb5757;
        }
      }

      .form-control {
        border-radius: 5px;
        border: 1px solid $secondaryColor;
      }
    }

    .box-reply-footer {
      height: 3rem;
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      .btn-photo {
        background-color: transparent;
        position: relative;
        border: none;
        padding: 0;
        &.disabled {
          svg {
            path {
              fill: #e1e1e1;
            }
          }

          input[type='file'] {
            cursor: not-allowed;
          }
        }
        input[type='file'] {
          opacity: 0;
          border-radius: 0;
        }

        svg {
          height: 1.5rem;
          width: auto;
        }
      }

      .btn-reply {
        padding: 0.5rem;
        width: auto;
        background-color: #ED1072;
        color: #ffffff;
        font-size: 1.25rem;
        line-height: 1.5rem;
        border-radius: 5px;
        border: none;
        box-shadow: none;
        .icon {
          margin-right: 5px;

          height: 1rem;
          width: auto;
        }
      }
    }
  }

  .modal-footer {
    justify-content: center;
    border-top: 0 !important;
    padding: 0 2rem 2rem 2rem;

    button {
      width: 80%;
      &.btn-primary-sr {
        color: #ffffff !important;
        background-color: $primaryColor;
        border-color: $primaryColor;
        border-radius: 5px;
        &:hover {
          color: rgba(255, 255, 255, 0.65) !important;
        }
      }
    }
  }
}

//modal-large
@media (min-width: 576px) {
  .modal-dialog.modal-large {
    max-width: 65rem !important;
  }
}

.modal-large {
  .tite-text {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
  }

  .topic-text {
    font-weight: bold;
    font-size: 14px;
    color: $Black;
    margin-bottom: 0;
  }

  .text-status {
    font-size: 14px;
    color: $Black;
    margin-bottom: 0;

    i.confirm {
      color: #62ba44;
      font-size: 1rem;
      margin-right: 5px;
    }

    i.wait {
      color: #f2c94c;
      font-size: 1rem;
      margin-right: 5px;
    }

    i.expire {
      color: #e94d4d;
      font-size: 1rem;
      margin-right: 5px;
    }
  }

  .box-sum {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 15px 0 15px;
    margin-left: 30px;

    .text-sum {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .box-order {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 15px 30px;
    margin-left: 30px;
    border-bottom: 1px solid #e0e0e0;

    .row-order {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .topic-order {
      display: flex;
      flex-direction: column;
    }
  }

  .box-order-wait {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 15px 15px;
    margin-left: 15px;
    border-bottom: 1px solid #e0e0e0;

    .row-order {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .form-group {
        padding: 0;
        width: 100%;
        margin-bottom: 0;
      }
    }

    .topic-order {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 30px;

      .row-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 14px;
      }
    }
  }

  .text-order {
    font-size: 16px;
    margin-bottom: 0;
  }

  ._blacl {
    color: $primaryColor;
  }

  ._blue {
    color: $primaryColor;
  }

  ._normal {
    font-weight: normal !important;
  }

  ._line-height-14 {
    line-height: 14px;
  }

  .wrap-topic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 30px;
  }

  .wrap-invoice {
    height: 63vh;
    overflow-y: scroll;
    background: $Background;
    border-radius: 5px;
    border: 1px solid $Background;
    padding: 15px;
    margin-bottom: 15px;

    .form-group {
      position: relative;
      background-color: #ffffff;
      padding: 15px;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &.confirm {
        border-left: 5px solid #62ba44;
      }

      &.wait {
        border-left: 5px solid #f2c94c;
      }

      &.expire {
        border-left: 5px solid #e94d4d;
      }
    }

    .form-check-input {
      width: 1.5rem;
      height: 1.5rem;
      left: 30px;
      z-index: 99999;
    }
  }

  .form-control {
    background-color: $Background;
    border-color: $Background;
  }

  .modal-content {
    border-radius: 5px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    border-bottom: 0 !important;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 1.5rem 2rem 1rem 2rem;
    position: relative;

    .btn-cloes {
      position: absolute;
      right: 1rem;
      top: 0.25rem;
      padding: 0;

      i {
        font-size: 1.5rem;
        color: $Black;
      }
    }
  }

  .modal-body.msg {
    width: 350px;
    display: flex;
    flex-direction: column;

    label.icon {
      margin-bottom: 0;
      color: $primaryColor;
      font-size: 7rem;
      line-height: 5rem;
      text-align: center;
      width: 100%;
      padding-bottom: 20px;
    }

    label.message {
      margin-bottom: 20px;
      color: $Black;
      font-size: 1rem;
      text-align: center;
      width: 100%;
      white-space: pre-wrap;
      font-weight: bold;
    }

    button {
      &:last-child {
        margin-bottom: 0;
      }

      width: 100%;
      margin-bottom: 20px;
    }
  }

  .modal-body {
    padding: 2rem;
    padding-top: 2.5rem;
    background-color: #ffffff;
    border-radius: 5px;

    .div-order {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      position: relative;
    }

    .box-preview {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999999999;
      height: 80%;

      img {
        height: 100%;
      }
    }

    .div-upload-slip {
      display: flex;
      flex-direction: column;
      padding-right: 2rem;
      min-width: 40%;
      max-width: 40%;
      border-right: 1px solid $Grey;

      .box-img {
        width: 15rem;
        height: 15rem;
        position: relative;

        .img-slip {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .btn-close {
          position: absolute;
          top: -0.5rem;
          right: -0.5rem;
          background-color: $Grey;
          border-radius: 50%;
          padding: 0;
          width: 1.5rem;
          height: 1.5rem;
          color: $Black;
        }
      }

      h3 {
        margin-bottom: 1rem;
        font-size: 1.25rem;
        color: $Black;
        font-weight: bold;
      }

      .btn-attach-modal {
        background-color: #e8ebff !important;
        color: $primaryColor !important;
        border-color: #e8ebff !important;
        width: 15rem;
        height: 15rem;
        position: relative;

        i {
          font-size: 3rem;
          line-height: 2.5rem;

          &::before {
            transform: rotate(45deg);
          }
        }

        input[type='file'] {
          width: 100%;
        }
      }

      .wrap-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 1rem;
      }
    }

    .box-invoice {
      display: flex;
      flex-direction: column;
      min-width: 60%;
      max-width: 60%;
      padding-left: 2rem;
      position: relative;

      button {
        &.btn-cloes {
          position: absolute;
          top: -35px;
          right: -15px;
          z-index: 999999;
          font-size: 2rem;
          line-height: 2rem;
          padding: 0;
        }
      }

      .header-invoice {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
          margin-bottom: 1rem;
          font-size: 1.25rem;
          color: $Black;
          font-weight: bold;
        }
      }
    }
  }

  .modal-footer {
    justify-content: center;
    border-top: 0 !important;
    padding: 0 2rem 2rem 2rem;

    button {
      width: 80%;
    }
  }
}

.color-red {
  color: red;
}

.delete-invoice {
  width: 100px;
  margin-left: auto;
}

.btn-blue-white {
  background-color: #e8ebff;
}

.modal-select {
  max-width: 90%;
}

.modal-editname {
  text-align: center;
  top: 25%;

  .btn {
    padding: 0 0.75rem;

    .btn-cloes {
      float: right;
      font-size: 1.5rem;
    }
  }

  .box-modaleditname {
    margin: 2.5rem 4rem;

    .editname {
      margin-top: 2.75rem;

      .input-editname {
        height: 2.5rem;
        margin-bottom: 1rem;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 5px;
        outline: none;
      }

      .btn-save-editname {
        padding: 0.5rem 0;
        background: $primaryColor;
        border: 1px solid $primaryColor;
        border-radius: 5px;
        color: #ffffff;
      }
    }

    p {
      padding-top: 1.25rem;
      text-align: center;
      color: #e94d4d;
    }
  }
}

.modal-movealbum {
  text-align: center;
  top: 25%;

  .btn {
    padding: 0 0.75rem;

    .btn-cloes {
      float: right;
      font-size: 1.5rem;
    }
  }

  .box-movealbum {
    margin: 2.5rem 4rem;

    .movealbum {
      margin-top: 2.75rem;

      .seletealbum {
        padding: 0;
        margin-bottom: 1rem;
      }

      .btn-confirmmove {
        padding: 0.5rem 0;
        background: $primaryColor;
        border: 1px solid$primaryColor;
        border-radius: 5px;
        color: #ffffff;
      }
    }
  }
}

.modal-delete {
  text-align: center;
  top: 25%;

  .box-modaldelete {
    margin: 3.3rem 4rem;

    .confirm {
      margin: 2.75rem 0 3.25rem 0;

      .btn-accept {
        margin: 1rem 0;
        padding: 0.5rem 0;
        background: #e94d4d;
        border: 1px solid #e94d4d;
        border-radius: 5px;
        color: #ffffff;
      }

      .btn-reject {
        padding: 0.5rem 0;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
      }
    }
  }
}

.modal-create {
  text-align: center;
  top: 3.5%;
  width: 90% !important;
  max-width: 90% !important;

  .box-modalcreate {
    .tite-text {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 3rem;
    }

    .btn {
      padding: 0 0.75rem;

      .btn-cloes {
        float: right;
        font-size: 1.5rem;
      }
    }

    .box-img {
      padding: 0 4rem;
      // display: flex;
      // flex-wrap: wrap;

      .div-upload {
        display: grid;
        // grid-template-columns: repeat(3, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); //respone
        grid-template-rows: repeat(4, 20vh);

        .btn-attach-modal {
          border-radius: 0;
          border: solid 1px #e0e0e0;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
          padding: 0.8rem;
          width: 100%;
          height: 100%;

          p {
            margin-top: 0.5rem;
            font-size: 1.2rem;
          }

          i {
            font-size: 5rem;
            color: $primaryColor;
            line-height: 0.1rem;
            padding-bottom: 0px;
          }

          .input-img {
            top: 13px;
            left: 77px;
            width: 190px;
            height: 170px;

            input[type='file'] {
              top: 13px;
              left: 77px;
              width: 190px;
              height: 170px;
            }
          }
        }

        .warp-img {
          display: flex;
          padding: 0.8rem;

          //   background: blue;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border: solid 1px #e0e0e0;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
          }
        }

        .img-thumbnail {
          flex: 1 1;
          background-color: #ffffff;
          border: solid 1px #e0e0e0;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
          height: 8rem;
          width: 100rem;
          width: 100%;
          height: 100%;
          margin: 1.125rem 0;
        }

        .box-close {
          margin: 1.25rem 1 0 0;
          position: relative;

          .btn-close {
            font-size: 1.5rem;
            color: rgba(0, 0, 0, 0.7);
            top: -28px;
            right: -25px;
            position: absolute;
            z-index: 9999;
          }
        }
      }
    }

    .box-text {
      padding: 0 4rem;
      border-left: 1px solid #e0e0e0;

      .title-addimage {
        text-align: start;
      }

      .selete-album {
        text-align: start;
        padding: 1.25rem 0;
      }
    }
  }

  .btn-comfirm {
    float: right;
    padding: 0.5rem 2rem;
    margin: 1rem 4rem;
  }
}

.modal-select {
  .btn {
    padding: 0 0.75rem;

    .btn-cloes {
      float: right;
      font-size: 1.5rem;
    }
  }

  .title-albumDetail {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
  }

  .back-btn {
    margin: 1rem 0;
  }

  .filter-tab {
    padding: 0;
    margin: 0;
  }

  .checkbox-media-all {
    padding: 0;

    input {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  .row {
    padding: 0.5rem 1rem 0 1rem;

    i {
      color: #e0e0e0;
      text-align: center;
      align-self: center;
    }
  }

  .div-recent {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(7, 1fr);

    .box-recent {
      position: relative;
      height: 12rem;
      margin: 0.5rem 0;
      border: solid 1px #e0e0e0;
      padding-bottom: 10px;

      .name-media {
        white-space: nowrap;
        min-width: 100px !important;
        max-width: 150px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 0.5rem;
      }

      .img-album {
        object-fit: cover;
        height: 75%;
        width: 100%;
      }

      input {
        display: block;
        position: absolute;
        margin-top: -80px;
      }

      .select-media {
        top: 0.25rem;
        left: 0.25rem;
        position: absolute;

        .index-select {
          background: #131414;
          opacity: 0.7;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          text-align: center;
          color: #ffffff;
        }
      }

      .checkbox-media {
        top: 5.4rem;
        right: 1.3rem;
        position: absolute;

        input {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    @media (max-width: 575.98px) {
      grid-template-columns: repeat(1, 1fr);

      .box-recent {
        // width: 100%;
        height: 12rem;
        position: relative;
        justify-self: center;

        .checkbox-media {
          top: 5.3rem;
          right: 1.3rem;
          position: absolute;

          input {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    @media (max-width: 768.98px) and (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 991.98px) and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1199.98px) and (min-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 1300px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media (min-width: 1400px) {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  .album-all {
    max-height: 440px;
    overflow: scroll;

    .div-album {
      display: grid;
      grid-gap: 0px;
      margin-bottom: 0;

      @media (max-width: 575.98px) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (max-width: 768.98px) and (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 991.98px) and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 1199.98px) and (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 1200px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media (min-width: 1300px) {
        grid-template-columns: repeat(6, 1fr);
      }

      .box-album-relative,
      .box-album {
        height: 11rem;
        width: 11rem;

        @media (max-width: 575.98px) {
          height: 10rem;
          width: 10rem;
        }

        @media (min-width: 1200px) {
          height: 10rem;
          width: 10rem;
        }

        @media (min-width: 1600px) {
          height: 14rem;
          width: 14rem;
        }
      }
    }
  }

  .btn-comfirm {
    margin-top: 2rem;
    float: right;
    padding: 0.5rem 2rem;
  }
}

.cur-point {
  cursor: pointer;
}
