@import "../styles/variables";

.set-price-logistic {
    overflow-y: scroll;

    word-break: break-all;
    .header {
        padding: 1em;
        background-color: $White;
        display: flex;
        justify-content: space-between;

        h5 {
            padding: 0;
            margin: 0;
        }
        .btn-with-icon {
            background-color: $White;
            color: $defaultInfoMain;
            width: auto;
        }
    }
    .body-list-logistic {
        .table-header {
            display: grid;
            grid-row: auto;
            grid-column: auto;
            grid-auto-rows: auto;
            grid-template-columns: 7% auto 20% 13% 15% 5% 5%;
            // grid-template-columns: minmax(100px, 5%) auto 20% 18% minmax(170px, 10%) minmax(25px, 4%) minmax(25px, 4%);
            background: $defaultBMain;
            border-radius: 10px 10px 0px 0px;
            color: $White;
            //    padding: 1em;
            margin-top: 1em;
            text-align: center;
            word-break: break-word;
            span {
                padding: 10px;
            }
                  div {
                padding: 10px;
            }
            div span {
                padding: 0;
            }
        }
            .table-content {
                   pointer-events: auto;
                   display: grid;
                   grid-row: auto;
                   grid-column: auto;
                   grid-auto-rows: auto;
                   grid-template-columns: 7% auto 20% 13% 15% 5% 5%;
                   background: $White;
                //    border-radius: 10px 0px 0px 0px;
                   color: $defaultTextPrimary;
                //    padding: 1em;
                   margin-top: 1em;
                   text-align: center;
                   word-break: break-word;
                   align-items: center;
                   span {
                       padding: 10px;
                   }
                   div {
                       padding: 10px;
                   }
                   div span {
                       padding: 0;
                   }
                }
    }

    .icon-apply-all-disabled {
        path {
            fill: $disableGray;
            stroke: $disableGray;
        }
    }

    .icon-checkbox {
        path {
            fill: $AISPrimaryMain;
            stroke: $AISPrimaryMain;
        }
    }
}
