@import "./variables";

.add-coupon {
  @extend .info-text;
  @extend .font-primary;

  .link-url {
      color: $primaryColor;
      cursor: pointer;
  }

  .label-description {
      color: $grey;
      font-size: 1.1rem;
      margin-left: 1.8rem;
  }

  .label-error {
    color: red;
    font-size: 1.1rem;
  }

  .section {
    background-color: white;
    padding: 1rem 2rem 0rem 2rem;
    margin-bottom: 2rem;

    .content {
      padding: 1rem 2rem;
    }
    hr {
      border-color: #f3f1f1;
    }
  }

  .input-title {
    @include primary-font;
    font-size: $caption;
    font-weight: 300;
  }

  .input-subtitle {
    @include primary-font;
    font-size: $caption;
    color: $grey;
  }

  .border-error {
    border: solid #de0000 2px;
  }

  .form-control:disabled {
    background: #f5f5f5 !important;
    border-color: #d9d9d9;
    color: #969696;
    cursor: not-allowed;
  }

  .cover-product-join {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }

    .text-select-product {
      color: #EC008C;
      font-size: $subTitle2;
      padding: 0px 8px;
    }

    .text-product-join-sku {
      color: $defaultTextTertiary;
      font-size: $subTitle2;
    }
  }



  .cover-radio-coupon {
    display: flex;
    flex-wrap: wrap;
  
    @media screen and (max-width: 1200px) {
      align-items: baseline;
    }

    .text-error-radio {
      color: #FF0000;
      font-size: $tooltip;
      padding-left: 28px;
      margin-top: 4px;
    }
  }
}


.icon-hint-tooltip {
  margin-left: 8px;

  svg {
    cursor: pointer;
      path {
        fill: $defaultActionMain;

        
      }

      &:hover {
        path {
          fill: $AISPrimaryMain;
        }
      }
  }
}