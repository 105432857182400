@import "./variables";

.add-promotion {
  @extend .info-text;
  @extend .font-primary;

  .link-url {
      color: $primaryColor;
      cursor: pointer;
  }

  .label-description {
      color: $grey;
      font-size: 1.1rem;
      margin-left: 1.8rem;
  }

  .section {
    background-color: white;
    padding: 1rem 2rem 0rem 2rem;
    margin-bottom: 2rem;

    .content {
      padding: 1rem 2rem;
    }
    hr {
      border-color: #f3f1f1;
    }
  }

  .input-title {
    @include primary-font;
    font-size: $caption;
    font-weight: 300;
  }

  .input-subtitle {
    @include primary-font;
    font-size: $caption;
    color: $grey;
  }

  .border-error {
    border: solid #de0000 2px;
  }

  .form-control:disabled {
    background: #f5f5f5 !important;
    border-color: #d9d9d9;
    color: #969696;
    cursor: not-allowed;
  }
}
