@import "./variables";

.order-history {
    @include primary-font;
    font-weight: 100;

    @media screen and (max-width: 1000px) {
        width: 50em;
        overflow-x: auto;
    }

    .header-order {
        display: flex;
        align-items: baseline;
        .content-header {
            font-size: $topic;
        }
    }

    .tab-order {
        ul {
            gap: 5px;
        }
    }

    .body-order-content {
        margin-top: 1em;

        // .header-order-container {
        //     @media (max-width: 1200px) {
        //         flex-direction: column;
        //     }
        // }
    }

    .nav-tabs {
        overflow: hidden;
        .nav-item {
            border-radius: 10px 10px 0 0;
            color: red;
            // background-color: #383633;
            background-color: #9d9d9d;
            border: 0;
            // width: 6em;
            width: 8em;
            // height: 3.5em;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                color: #ffffff;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .nav-tabs .nav-link:hover {
        border: 0;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        border: 0px solid #fff !important;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: #fff !important;
        background-color: #383633 !important;
        border: 0;
        border-radius: 10px 10px 0 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-order {
        width: 100%;
        height: 6em;
        padding: 1em;
        background-color: #fff;

        // input[type="text"] {
        //     background: #f9f9f9;
        //     box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
        //     border-radius: 5px;
        // }
    }

    .list-order-item {
        // padding: 0 1em;
        word-wrap: break-word;
    }

    .pagination-top-order {
        display: flow-root;
        margin-bottom: 10px;
    }

    .msg-tracking-no {
        color: $DarkBlue;
        font-size: $content;
        word-break: break-word;
    }



}

.btn-download-container {
    svg {
        &.icon-download {
            path {
                fill: $AISPrimaryMain;
            }
        }
    }
    // svg {
    //     path {
    //         fill: $AISPrimaryMain;
    //     }
    // }
}

.search-container {
    display: flex;
    flex-direction: row;
    gap: 2em;
    padding: 1em;
    background-color: $White;
}

