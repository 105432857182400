@import '../../styles/variables';

#modalPreview {
    font-size: 10px;
    width: 35em;
    padding: 1.5em;
    position: absolute;
    right: 0;
    bottom: -18px;
    z-index: 9999;
    .modal-preview-container {
        // border-bottom: 1px solid grey;
        // margin: 1em;
        padding: 1em;
    }

    .modal-preview-container {
        background-color: #f9f9f9;
    }

    .sku-select-preview {
        border-bottom: 1px solid #e1e1e1;
        padding: 1em 0;
    }

    .color-modal-preview {
    }

    // .color-modal-preview > span {
    //     margin: 0 0.5em;
    // }

    // .color-modal-preview > span:first-child {
    //     margin: 0;
    // }

    .item-preview-container {
        border-bottom: 1px solid #e1e1e1;
        // margin: 1em;
        padding: 1em;
    }

    .modal-preview-hide {
        display: none;
    }

    .close {
        position: absolute;
        right: 22px;
        top: 10px;
        width: 32px;
        height: 32px;
        opacity: 0.3;
        cursor: pointer;
    }
    .close:hover {
        opacity: 1;
    }
    .close:before,
    .close:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 2px;
        background-color: #ffffff;
    }
    .close:before {
        transform: rotate(45deg);
    }
    .close:after {
        transform: rotate(-45deg);
    }

    .arrow-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $defaultInfoMain;
        transform: rotate(-45deg);
        padding-bottom: 0.5em;
        right: 0px;
        bottom: 0;
        position: absolute;
    }

    .preview-select-active {
        border: 1px solid orange;
        padding: 0.5em;
        margin: 0.5em;
        color: $defaultInfoMain;
        position: relative;
        background-color: #ffffff;
    }

    .preview-select-active:after {
        content: "";
        transform: rotate(-45deg);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $defaultInfoMain;
        position: absolute;
        bottom: -1px;
        right: -4px;
        // margin: 0.5em;
    }

    .preview-select-notactive {
        padding: 0.5em;
        margin: 0.5em;
        border: 1px solid #e9e9e9;
        background-color: #ffffff;
    }

    .product-image {
        width:  50px;
        height: 50px;
        img {
            width:  50px;
            height: 50px;
        }
    }

    
}
