@import './variables';

.header-product {
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 0;
        margin-bottom: 0em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }

    .header-gap {
        gap: 12px;
    }
}

.add-product-btn {
    cursor: pointer;
}

.search-product {
    height: 5em;
    // background-color: white;
    display: flex;
    align-items: center;
    .input-search {
        // margin-left: 7em;
    }
    .nav-tabs {
        border-bottom: 0px solid #dee2e6;
    }

    ul {
        li {
            margin-right: 5px;
        }
    }
}

.pagination-product-header {
    @media screen and (max-width: 1150px){
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
}

.body-product {
    height: 80vh;
}

.list-product {
    height: 100%;
    @media screen and (max-width: 1278px){
        width: 50em;
        overflow-x: auto;
    }
    .grid-head-col {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        // grid-template-rows: 2em 2em 2em 2em 2em;
        grid-auto-rows: auto;
        // grid-template-columns: 1fr 6fr 2fr 2fr 4fr repeat(3,2fr) 3fr;
        // grid-template-columns: 4em 18em 7em 5em 14em repeat(3,5em) repeat(2,2em);
        grid-template-columns: 4% 15% 5% 10% 6% 8% 8% 8% 8% 6% 6% 6% 10%;
        text-align: center;
        font-size: $caption;
        
    }

    .grid-head-col:first-child {
        background: $defaultBMain;
        border-radius: 10px 10px 0px 0px;
        color: white;
    }

    .grid-head-col > div{
        padding: 1em 0.5em;
        margin-bottom: 0.5em;
    }

    .grid-col {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        // grid-template-rows: 2em 2em 2em 2em 2em;
        grid-auto-rows: auto;
        // grid-template-columns: 4% auto 6% 10% 8% 8% 9% 7% 5% 8% 8% 9%;
        grid-template-columns: 4% 13% 7% 10% 7% auto 10%;
        font-family: "DBHeavent";
        font-weight: lighter;
        background-color: #fff;
        margin-bottom: 1rem;
        word-break: break-word;
    }

    .grid-col > div{
        padding: 0.5em 0.5em;
        // margin-bottom: 1em;
        background-color: white;
        display: grid;
        // grid-template-rows: repeat(999,max-content)
    }

    .grid-col .product-category{
        svg {
            width: auto;
            height: auto;
            max-width: 15px;
            path {
                fill: $infoMain;
            }
        }
    }

    // .grid-col > div > span {
    //     padding: 4px;
    //     margin-bottom: 4px;
    // }

    .product-name {
        gap: 12px
    }

    .product-sku-list-table{
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-template-columns: 15% 15% 16% 17% 12% 13% 12%;
        font-size: $caption;
        grid-auto-rows: max-content;

        div {
            padding: 0.5em 0.5em;
        }
    }
}

.icon-search-product {
    position: absolute;
    right: 3%;
    top: 7px;
}

.search-product-input {
    padding-left: 1em !important;
}

.pagination-top{
    display: flow-root;
    margin-bottom: 10px;
    margin-top: 1em;
}

.icon-link {
    cursor: pointer;
}

.label-link {
    cursor: pointer;
    color: $defaultInfoMain;
}

.label-link:hover{
    text-decoration: underline;
}

.icon-manage {
    path {
        fill : $AISPrimaryMain;
    }
}