@import "./variables";

.react-tabs {

    @extend .font-secondary;
    -webkit-tap-highlight-color: transparent;
  
    &__tab-list {
      // border-bottom: 1px solid #aaa;
      margin: 0;
      padding: 0;
    }
  
    &__tab {
      display: inline-block;
      border: 1px solid transparent;
      border-bottom: none;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 6px 12px;
      cursor: pointer;
      background-color: gray;
      border-radius: 5px 5px 0 0;
      color: white;
  
      &--selected {
          background: #2a2a2a;
          border-color: #aaa;
          color: white;
          border-radius: 5px 5px 0 0;
      }
  
      &--disabled {
        color: GrayText;
        cursor: default;
      }
  
      &:focus {
        box-shadow: 0 0 5px hsl(208, 99%, 50%);
        border-color: hsl(208, 99%, 50%);
        outline: none;
  
        &:after {
          content: "";
          position: absolute;
          height: 5px;
          left: -4px;
          right: -4px;
          bottom: -5px;
          background: #fff;
        }
      }
    }
  
    &__tab-panel {
      display: none;
  
      &--selected {
        display: block;
        // background-color: white;
        // padding: 1rem;
      }
    }
  }
  