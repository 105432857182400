@import "../../styles/variables";

.modal-edit-product-excel {
    width: 100%;
    height: 100%;
    font-weight: 100;
    font-family: DBHeavent;
    .title {
        color: #252527;
        font-size: 32px !important;
        font-family: DBHeavent !important;
        margin-right: 8px;
    }
    .sub {
        color: #6b6b6b;
        font-family: DBHeavent;
        font-size: 24px;
    }
    .sub-txt {
        color: #ec008c;
        font-family: DBHeavent;
        font-size: 24px;
    }
    // .sub-txt:nth-child(2)::before {
    //     content: "|";
    //     color: rgba(42, 44, 45, 0.25);
    //     margin-right: 5px;
    // }

    .sub-txt:nth-child(1)::before {
        display: none;
    }

    .sub-txt::before {
        content: "|";
        color: rgba(42, 44, 45, 0.25);
        margin-right: 5px;
    }

    .modal-dialog {
        // max-width: 500px;
        // margin: 1rem auto;
        // height: 60%;
    }

    @media only screen and (min-width: 576px) {
        .modal-dialog {
            width: 80% !important;
            max-width: 80% !important;
        }
    }

    .modal-title {
        width: 100%;
    }

    .modal-content {
        height: 90vh;
    }
    .modal-body {
        background-color: #f9f9f9;
        height: 85%;
        padding: 0 !important;
        overflow: hidden;

    
        .body-content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;

            .header-detail {
                background-color: $defaultInfoBg;
                color: $defaultInfoMain;
                width: 100%;
                padding: 0 32px;
            }

            .cover-title-modal-select-product {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                gap: 2px;
                border-bottom: 1px solid #E9E9E9;

                .container-left {
                    width: 30%;
                    background: white;
                    padding: 8px 12px;
                }

                .container-right {
                    width: 100%;
                    background: white;
                    padding: 8px 32px;
                }
            }
        }
        .bg-white {
            padding: 1rem;
            width: 100%;
            height: 100%;
            display: flex;
            // justify-content: space-between;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
        }

        .container-shop-product {
            width: 100%;
            display: flex;
            height: 100%;
            gap: 2px;
            // overflow: auto;

            .cover-title-modal-select-product {
                padding-bottom: 12px;
                border-bottom: 1px solid red;
            }

            .list-shop-container {
                background-color: $White;
                width: 30%;
                // max-width: 236px;
                padding: 16px 12px;
            }
            .list-product-container {
                background-color: $White;
                max-width: 100%;
                flex: 1;
                padding: 16px 28px;

                .list-card {
                    width: 100%;
                    height: 100%;
                    background: #f9f9f9;
                    padding: 1rem;
                    border-radius: 10px 10px 0px 0px;
                    // max-height: 50vh;
                    height: calc(90vh - 450px);
                    overflow: scroll;
                    margin-top: 8px;
                    display: grid;
                    grid-template-columns: repeat(5, minmax(100px, 1fr));
                    gap: 15px;
                }
                .no-list-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: calc(90vh - 450px);
                    border-radius: 10px;
                    margin-top: 0.5rem;
                    background-color: #f9f9f9;
                    color: var(--default-text-tertiary, var(--default-text-teriary, #6b6b6b));
                }
                .search-box {
                    width: 500px;
                }
                .dropdown {
                    width: 12.5rem;
                }
                .text-teriary {
                    color: #6b6b6b;
                    font-family: DBHeavent;
                    font-size: 22px;
                }

                .cover-pagination-select-product {
                    background: #f9f9f9;
                    border-radius: 0px 0px 10px 10px;

                    .pagination-div {
                        margin: 0px;
                        padding: 14px 0px;
                    }
                }

            }
        }

        .bg-grey {
            width: 100%;
            background: #f9f9f9;
        }
        .pagination {
            background-color: #f9f9f9;
        }
    }

    .modal-footer {
        display: flex;
        justify-content: center;
        min-height: 65px;
        align-items: center;
        border: none;

        .footer {
            gap: 40px;
            // width: 360px;
            display: flex;
            justify-content: space-between;
        }
    }

    .btn-submit {
        svg {
            path {
                fill: $defaultTextPrimary;
            }
        }
        &.disable-btn {
            background-color: #dbdbdb !important;
            border-color: #dbdbdb;
            color: rgba(42, 44, 45, 0.25) !important;
            img {
                filter: grayscale(1) brightness(4);
            }
        }
    }

    .btn-cancel {
        &.disable-btn {
            border-color: #dbdbdb !important;
            color: #dbdbdb !important;
        }
    }

    .card-product {
        width: 100%;
        background-color: #fff;
        &.active {
            border: solid 1px #b2d234;
        }
        &.disble {
            background-color: #dbdbdb !important;
            border: solid 1px #dbdbdb;
        }
    }

    .require::after {
        content: " * ";
        color: red;
    }
}
