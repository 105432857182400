@import '../../styles/variables';

.modal-select-list {
  .modal-body {
    padding: 0 !important;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .txt-topic {
        @include secondary-font();
        font-size: $topic;
      }
      padding: 1rem;
    }

    .content {
      padding: 1rem;

      .check-box-container {
        display: flex;
        justify-content: center;
        width: 100%;

        .check-box-list {
          display: flex;
          flex-direction: column;

          width: 33.2%;
          height: 350px;
          background-color: $White;
          border: 1px solid #e9e9e9;
          overflow: scroll;

          .item-list {
            width: 100%;
            padding: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // background-color: lemonchiffon;
            .icon-arrow {
              img {
                width: 0.5rem;
                // color: lightcoral;
              }
              svg {
                &.gray {
                  path {
                    fill: #AFAEAE;
                  }
                }
                &.pink{
                  path{
                    fill: #ED1072;
                }
              }
            }
          }
          // .icon-arrow:last-child {
          //   display: none;
          // }

            .wrap-check-box {
              .ant-checkbox-wrapper {
                margin-right: 1rem;
              }

            .ant-checkbox-disabled .ant-checkbox-inner {
              background-color: #DBDBDB !important;
              border-color: #DBDBDB !important;
          }

              .ant-checkbox-inner {
                width: 15px !important;
                height: 15px !important;
              }

              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #ec008c !important;
                border-color: #ec008c !important;
              }

              .ant-checkbox-wrapper:hover .ant-checkbox-inner,
              .ant-checkbox:hover .ant-checkbox-inner,
              .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: #ec008c !important;
              }

              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #ec008c !important;
                border-color: #ec008c !important;
              }

              .ant-checkbox-inner::after {
                width: 4px !important;
                height: 9px !important;
              }
            }

            .wrap-check-box-disabled {
              .ant-checkbox-wrapper {
                margin-right: 1rem;
              }

            .ant-checkbox-disabled .ant-checkbox-inner {
              background-color: #DBDBDB !important;
              border-color: #DBDBDB !important;
          }

              .ant-checkbox-inner {
                width: 15px !important;
                height: 15px !important;
              }

              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #DBDBDB !important;
                border-color: #DBDBDB !important;
              }

              .ant-checkbox-wrapper:hover .ant-checkbox-inner,
              .ant-checkbox:hover .ant-checkbox-inner,
              .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: #DBDBDB !important;
              }

              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #DBDBDB !important;
                border-color: #DBDBDB !important;
              }

              .ant-checkbox-inner::after {
                width: 4px !important;
                height: 9px !important;
              }

              .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
                border-color: white !important; 
                -webkit-animation-name: none;
                animation-name: none;
            }
            }

            .color-pink {
              color: #ED1072;
            }
          }
        }
        .check-box-list:last-child {
          .item-list{
            padding: 9px;
            .icon-arrow {
              display: none;
            }
          }
        }
      }

      .item-selected {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: max-content;
        border: 1px solid #bfbfbf;
        padding: 5px;

        .text-item-selected {
          @include primary-font();
          font-style: normal;
          font-weight: 100;
          font-size: $caption;
          color: $darkBlack;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      padding: 1rem;
    }

    .selected-list-cat {
      height: 21vh;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow: scroll;
      gap: 5px;
    }
  }

 

  
}
