@import "./variables";

.shop-addition {

    @extend .info-text;
    @extend .font-primary;

    section {
        background-color: $White; 
        padding: 2rem;
        margin: 1rem 0;
    }

    section.shop-addition {
        background-color: #f9f9f9; 
    }

    section.form-submit {
        margin:0;
        background-color: transparent;
    }

    ::placeholder {
        color:#f9f9f9; 
        font-size: 12px;
        font-weight: 100;
    }

    .select-bank {
        width: 100%;
        background-color: #FFF9F3;
        padding: 0.3rem 0.6rem;
        border-radius: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span{
            margin-left: 0.4rem;
            font-weight: 700;
            color: $Orange;
        }
    }
    

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col12 {
        padding: 0 1rem !important;
    }

    .form-label {
        margin-bottom: 0.2rem;
        font-weight: 600;
        font-size: 12px;
    }
    .row {
        margin: 1.5rem 0;
    }

    h4 {
        padding-left: 1rem
    }

    span.title {
        padding-left: 1rem;
    }

    .form-title {
        font-weight: 700;
    }
    .form-detail {
        color: $Darkgray;
        font-weight: 100;
    }

    .shop-profile-image {
        width: 100%;
        max-width: 128px;
        position: relative;
    }
    
    svg.shop-profile-camera {
        right: 0;
        position: absolute;
        bottom: 0;
    }
    
    .shop-profile {
        &-image {
            width: 100%;
            max-width: 128px;
            position: relative;

            &-camera {
                right: 0;
                position: absolute;
                bottom: 0;
            }

            a {
                transition: background 22s ease-in-out;
            }

            a:hover {
                svg {
                    circle {
                        fill: #f7ab53 !important;
                    }
                }
            }
        }
    }
   
}