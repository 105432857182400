@import "../../styles/variables";

@mixin gridLayoutContent {
    flex: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    > .content-level-category-widget {
        width: 100%;
        height: calc(45vh - 50px);
        overflow-y: auto;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: $White;
        padding: 1rem 0.5rem 1rem 1rem;

        &.isTypeDefault {
            height: 45vh;

            :is(.icon-edit, .icon-trash) {
                display: none;
            }
        }

        &.isHideIconArrow {
            .icon-arrow-right {
                display: none;
            }
        }

        > div {
            width: 100%;
            cursor: pointer;
            :hover {
                cursor: pointer;
            }
        }
    }

    > .footer-level-category-widget {
        box-shadow: 0px 0px 4px 0px #00000040;
        background: $defaultBg;
        height: 100%;
        width: 100%;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        width: calc(100% / 3);
    }
}

.wrapper-category-widget {
    background-color: $ActionDisabled;
    padding: 0.5rem;

    .wrapper-inner-category-widget {
        box-sizing: border-box;
        height: 45vh;

        .content-category-widget {
            @include gridLayoutContent();
            height: calc(45vh - 50px);

            &.isTypeDefault {
                height: 45vh;
            }

            &.content-empty-category-widget {
                background-color: $White;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    path {
                        fill: #d8c47a;
                    }
                }
            }
        }

        .footer-category-widget {
            height: 50px;
            box-shadow: 0px 0px 4px 0px #00000040;

            @include gridLayoutContent();

            .buttonAddDisable {
                pointer-events: none;
                cursor: default;
                .btn-add {
                    filter: grayscale(1) brightness(2.4);
                }
                * {
                    cursor: default;
                }
            }

            &.footer-empty-category-widget {
                // height: 100%;
                background-color: $defaultBg;
                box-shadow: 0px 0px 4px 0px #00000040;
                width: 100%;
                flex: calc(100% / 1);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .btn-add {
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
}
