
.form-content {

    position: relative;
    width: 100%;
    height: 162px;
    background-color: #fcfff3;
    border-radius: 0;
    border: solid #e4f1bd;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
        height: 26px;
        font-size: 24px;
    }

    input[type='file'] {
        position: absolute;
        opacity: 0;
        padding: 19px;
        width: 100%;
        height: 162px;
        border-radius: 0;
    }
}
