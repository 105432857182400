// @import './variables';

// .dashboard {

// .date {
//     width: 100%;
//     height: 4em;
//     text-align: left;
//     display: flex;
//     align-items: center;
//     padding: 0 2em;
//     font-size: 20px;
//     background-color: white;
// }

// .tab-order {
//     ul {
//         gap: 5px;
//     }
// }

// .nav-tabs {
//     overflow: hidden;
//     .nav-item {
//         border-radius: 10px 10px 0 0;
//         color: red;
//         // background-color: #383633;
//         background-color: #9d9d9d;
//         border: 0;
//         width: 9em;
//         height: 3.5em;
//         text-align: center;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         a {
//             color: #ffffff;
//             padding-left: 0;
//             padding-right: 0;
//         }
//     }
// }
// .nav-tabs .nav-link:hover {
//     border: 0;
//     border-top-left-radius: 0.25rem;
//     border-top-right-radius: 0.25rem;
// }

// .nav-tabs .nav-link:hover,
// .nav-tabs .nav-link:focus {
//     border: 0px solid #fff !important;
// }

// .nav-tabs .nav-link.active,
// .nav-tabs .nav-item.show .nav-link {
//     color: #fff !important;
//     background-color: #383633 !important;
//     border: 0;
//     border-radius: 10px 10px 0 0;
//     height: 100%;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// img {
//     width: 100%;
// }

// .Dropdown-control{
//     width: 25em;
//     @include primary-font;
//     font-weight: 300;
//     color: black;
// }

// .pick-week{
//     @include primary-font;
//     font-weight: 100;
//     font-size: 14px;
//     display: flex;
//     align-items: center;
// }

// }
@import "./variables";

.dashboard {
    @media only screen and (max-width: 1000px) {
        width: 42em;
    }

    section {
        background-color: $White;
        padding: 1em;
        margin-top: 1em;
    }

    .filter-box {
        height: 68px;
        // width: 1134px;
        width: 100%;
        background-color: white;
        margin-bottom: 20px;
        display: flex;
        flex: row wrap;
        align-items: center;
        gap: 20px;
        padding: 1em;
        justify-content: space-between;

        .filter-container {
            width: 75%;
            display: flex;
            gap: 30px;
        }
    }

    .box-2 {
        // height: 510px;
        // width: 1134px;
        width: 100%;
        border-radius: 5px;
        padding: 1em;
        background-color: white;
    }

    // #reactgooglegraph-1 {
    svg {
        // g {
        //     rect {
        //         stroke-dasharray: 4, 8 !important;
        //         stroke: #bfbfbf !important;
        //         stroke-width: 1px;
        //         height: 0.01px;
        //     }
        // }
        g:nth-child(1) {
            rect {
                stroke-dasharray: 4, 10 !important;
                stroke: #bfbfbf !important;
                stroke-width: 1px;
                height: 0.01px;

                rect:nth-child(0) {
                    display: none;
                }
            }
        }
        g {
            g {
                g {
                    rect:nth-child(1) {
                        // display: none;
                        stroke-dasharray: 0, 1 !important;
                    }
                }
            }
        }
    }
    // }

    .content-dashboard {
        div {
            margin: 0;
        }

        .lkPlSK {
            padding: 1em 0;
        }
    }

    .table-product {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 20%;
        border-radius: 10px 10px 0px 0px;
        background: $defaultBMain;
        color: $White;
        padding: 0 1em;
        text-align: center;
        word-break: break-word;
        font-size: $body2;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
        }
        div span {
            padding: 0;
        }
    }
    .table-product-content {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 20%;
        //    border-radius: 10px 10px 0px 0px;
        background: $defaultBg;
        // background-color: red;
        color: $defaultTextPrimary;
        padding: 0.5em 1em;
        text-align: center;
        word-break: break-word;
        // margin-bottom: 2em;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
            background-color: $White;
            // height: -webkit-fill-available;

            display: flex;
            align-items: center;
            justify-content: center;
        }
        div span {
            padding: 0;
        }

        .show-item {
            gap: 10px;
        }
    }

    .table-hot-product {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 20%;
        border-radius: 10px 10px 0px 0px;
        background: $defaultBMain;
        color: $White;
        padding: 0 1em;
        margin-top: 1em;
        text-align: center;
        word-break: break-word;
        font-size: $body2;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
        }
        div span {
            padding: 0;
        }
    }
    .table-hot-product-content {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 20%;
        //    border-radius: 10px 10px 0px 0px;
        background: $defaultBg;
        color: $defaultTextPrimary;
        padding: 0.5em 1em;
        //    margin-top: 1em;
        // margin-bottom: 2em;
        text-align: center;
        word-break: break-word;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
            background-color: $White;
        }
        div span {
            padding: 0;
        }
    }

    .table-promocode {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 20% 15% 15% 15%;
        border-radius: 10px 10px 0px 0px;
        background: $defaultBMain;
        color: $White;
        padding: 0 1em;
        margin-top: 1em;
        text-align: center;
        word-break: break-word;
        font-size: $body2;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
        }
        div span {
            padding: 0;
        }
    }
    .table-promocode-content {
        display: grid;
        grid-row: auto;
        grid-column: auto;
        grid-auto-rows: auto;
        grid-template-columns: 10% auto 20% 15% 15% 15%;
        //    border-radius: 10px 10px 0px 0px;
        background: $defaultBg;
        color: $defaultTextPrimary;
        padding: 0.5em 1em;
        // margin-bottom: 2em;
        //    margin-top: 1em;
        text-align: center;
        word-break: break-word;
        span {
            padding: 10px;
        }
        div {
            padding: 10px;
            background-color: $White;
            height: -webkit-fill-available;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        div span {
            padding: 0;
        }
    }

    #input-select {
        flex-grow: 1;
        margin-left: 0.5em;
        width: 70%;
    }

    .icon-download {
        path {
            fill: $AISPrimaryMain;
        }
    }

    .H6 {
        font-weight: 700;
    }

    .sub-title-1 {
        font-weight: 700;
    }

    .graph-dashboard {
        .icon-hint {
            cursor: pointer;
            max-width: 30px;
            path {
                fill: $defaultTextInput;
            }
        }
        .icon-hint:hover {
            path {
                fill: $AISPrimaryMain;
            }
        }
        .google-visualization-tooltip {
            border: none !important;
            border-radius: none !important;
            // background-color: white;
            // position: absolute;
            box-shadow: none !important;
            // font-size: 12px;
            // padding: 0px;
            -moz-box-shadow: none !important;
            -webkit-box-shadow: none !important;
            // z-index: 10000;
        }
    }

    .nav-tabs {
        overflow: hidden;
        width: auto;
        .nav-item {
            border-radius: 10px 10px 0 0;
            color: red;
            // background-color: #383633;
            background-color: #9d9d9d;
            border: 0;
            width: 10em;
            height: 3em;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 2px;

            a {
                color: #ffffff;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .nav-link {
            width: -webkit-fill-available;
            background-color: none !important;
        }
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        border-radius: 5px 5px 0 0;
        background-color: none !important;
        color: $White;
        align-items: center;
        display: flex;
        gap: 5px;

        svg {
            path {
                fill: $White;
            }
        }

        .icon-hint:hover {
            path {
                fill: $AISPrimaryMain;
            }
        }
    }

    .nav-tabs .nav-link {
        text-align: center;
        justify-content: center;
        background-color: none;
        color: $White;
        align-items: center;
        display: flex;
        gap: 5px;
        svg {
            path {
                fill: $White;
            }
        }

        .icon-hint:hover {
            path {
                fill: $AISPrimaryMain;
            }
        }
    }

    .ant-select-selection-item {
        height: -webkit-fill-available;
        padding: 0 !important;
        display: flex;
        align-items: center;
        img {
            height: 30px;
        }
    }

    .btn-cancel {
        width: max-content;
    }

    .period-input {
        .input-select {
            width: max-content !important;
        }
    }

    .dashboard-top-view {
        z-index: 998;
        :hover {
            z-index: 999;
        }

        .tooltip-dashboard-top-view-scroll {
            &::-webkit-scrollbar {
                width: 0.4em;
            }
            &::-webkit-scrollbar,
            &::-webkit-scrollbar-thumb {
                overflow: visible;
                border-radius: 4px;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.2);
            }
        }
        .groups-row {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            margin-top: 23px;
        }

        .group-container {
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 5px;
            border-radius: 10px;
            flex-shrink: 0;
            width: 150px;
        }

        .dots {
            min-width: 10px;
            min-height: 10px;
            border-radius: 50%;
            background-color: #000;
            margin-top: 6px;
        }

        .group-image {
            max-width: 25px;
            max-height: 25px;
            border-radius: 5px;
        }

        .group-text {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 22px;
        }

        &.card-topview {
            border-radius: 10px;
            // background: var(--default-white, #fff);
            box-shadow:
                -4px 4px 10px 0px rgba(255, 255, 255, 0.6),
                4px 4px 10px 0px rgba(41, 45, 47, 0.1);
        }

        .google-visualization-tooltip {
            border: none !important;
            border-radius: none !important;
            // background-color: white;
            // position: absolute;
            box-shadow: none !important;
            // font-size: 12px;
            // padding: 0px;
            -moz-box-shadow: none !important;
            -webkit-box-shadow: none !important;
            // z-index: 10000;
        }
    }
    
    #reactgooglegraph-1 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(3) ,
    #reactgooglegraph-2 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(3) {
        > path:nth-child(1) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(2) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(3) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(4) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(5) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(6) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(7) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(8) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(9) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
        > path:nth-child(10) {
            &:hover,&:active,&:focus,&:focus-within,&:focus-visible,&:visited {
                filter: brightness(1.2);
                stroke-width: 4;
            }
        }
    }
}
