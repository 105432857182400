@import "./variables";

.pl-pr-10rem {
    padding: 1em 10rem;
    margin-bottom: 1em;
}

input[type=number] {
    color: $Darkgray;
    &:focus {
        color: $Darkgray;
      }
}

.two-clumn {
    padding: 0 !important;
    text-align: right !important;
    padding-left: 1rem !important;
    padding-right: 3.5rem !important;
}
.col-2-atl {
    flex: 0 0 12.333333%;
    max-width: 12.333333%;
    margin: 0 0.5rem;
}
.col-1-atl {
    flex: 0 0 7.333333%;
    max-width: 7.333333%;
}

.general-live-container {
    background-color: $White;
    margin-bottom: 1rem;
    @extend .info-text;
    @extend .font-primary;
}

.paid-time-container {
    background-color: $White;
    margin-bottom: 1rem;
    @extend .info-text;
    @extend .font-primary;
}

.image-live-container {
    background-color: $White;
    margin-bottom: 1rem;
    // margin-right: 1em;


    .upload-detail {
        font-family: "DBHeavent" !important;
        font-style: normal;
        font-weight: 300;
    }

    .btn-upload-image {
        background-color: $oldLace;
        border: none;
        width: 12em;
        height: 12em;
        border-radius: 10px;
        color: $Orange;
        font-family: "DBHeavent" !important;
        font-style: normal;
        font-weight: 300;
        .icon-camera {
            width: 100%;
            height: 30px;
        }
    }
}

.date-live-container {
    background-color: $White;
    margin-top: 4px;
    margin-bottom: 1rem;
}

.setting-order {
    background-color: $White;
    margin-bottom: 1rem;

    .add-order {
        color: $defaultInfoMain;
        .icon-plus {
            width: 25px;
            height: 25px;
        }
    }

    .order-list {
        background-color: #f9f9f9;
        font-size: 12px;
        font-family: "DBHeavent" !important;
        font-weight: 100;
        padding: 2em 0;
        .btn-apply-discount {
            border: 1px solid $Orange;
            color: $Orange;
            background-color: $White;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            font-family: "DBHeavent" !important;
            width: 15em;
            height: 3em;
        }
        .item-list {
            background-color: $White;
            width: 93%;
            // height: 30em;
            margin: auto;
            padding: 2em 0;
        }

        .input-group {
            .form-control {
                border-radius: 0.313rem;
                padding-right: 1.5em;
                padding-left: 1.5em;
                text-align: center;
                color: #9d9d9d;

            }
            .price-number{
                padding-left: 0em;
                text-align: right;
                padding-top: 8px;
                padding-right: 53%;
                font-size: 0.9rem !important;
}
            }
            .input-group-prepend {
                position: absolute;
                z-index: 4;
                .input-group-text {
                    border: 0;
                    background: transparent;
                    color: #9d9d9d;
                    font-weight: 100;
                }
                
            }
            .text-number {
                border: 0;
                background: transparent;
                color: #9d9d9d;
                font-weight: 100;
                font-size: 0.9rem !important;
                padding-top: 8px;
            }
            .input-group-append {
                position: absolute;
                z-index: 4;
                right: 0;
                .input-group-text {
                    border: 0;
                    background: transparent;
                    color: #9d9d9d;
                    font-weight: 100;
                }
            }
        }
        .form-control {
            border-radius: 0.313rem;
            // padding-left: 2em;
            // padding-right: 2em;
            text-align: center;
            color: #9d9d9d !important;
            background: #ffffff;
            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
            border-radius: 10px !important;
        }
}


.btn-cancel {
    border: 1px solid $AISPrimaryMain;
    color: $AISPrimaryMain;
    background-color: $White;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    // width: 6em;
    // height: 2em;
}
.btn-submit {
    border: 1px solid $AISPrimaryMain;
    color: $defaultTextPrimary;
    background-color: $AISPrimaryMain;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: "DBHeavent" !important;
    // width: 6em;
    // height: 2em;
}

.item-sku > div {
    display: flex;
    align-content: center;
    align-items: center;
}

.item-list .div .span {
    font-weight: lighter;
}

.live-cover-image {
    position: relative;
    max-width: 12em;
    max-height: 12em;
    width: 100%;
    height: 100%;
    .image-preview {
       max-width: 12em;
       max-height: 12em;
       width: 100%;
       height: 100%;
       margin: 0.5em 0.5em 0 0;
   }
   
    .upload-icon {
       position: absolute;
       bottom: -20px;
       right: -10px;
       cursor: pointer;
   }
}
.input-img-wrap {
    width: 168px;
}

.text-detail {
    color: $Darkgray;
    font-size: $tooltip;
}

input.form-control.two-clumn.error-color  {
    color: #de0000 !important;
}

input.form-control.error-field {
    border: solid #de0000 2px;
}

span.error-message {
    @extend .info-text;
    @extend .font-primary;

    // font-size: 0.75rem;
    color: #de0000;
}

.msg-error {
    bottom: -15px;
    position: absolute;
    color: $textRed;
    font-size: $note;
}
