@import './variables';


.haeder-user-chat{
    // z-index: 99;
    display: block;
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    padding: 1rem 0;
    background-color: #ffffff;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    h2{
        color: $Black;
        font-size: 1.75rem;
        margin: 0 !important;
        width: 100%;
    }
}
.wrap-box-user{
    display: flex;
    flex-direction: column;
    overflow: scroll;
    // height: -webkit-fill-available;
    height: 76%;
    // padding-top: 145px;
    z-index: 5;
}
.box-customer {
    border: 1px solid $secondaryColor;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    &.active-box{
        background-color: $Grey;
    }
    .box-img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;
        img{
            height: 40px;
            width: 40px;
            border-radius: 50%;
        }
        .social{
            position: absolute;
            bottom: -1px;
            right: -3px;
            width: 1.15rem;
            height: 1.15rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 0.75rem;
            z-index: 999;
            &.facebook{
                background-color: $Facebook;
            }
            &.line{
                background-color: $Line;
            }
            &.phone{
                background-color: $primaryColor;
            }
            i::before{
                font-size: 0.75rem;
                color: #ffffff;
            }
        }
    }
    .detail{
        display: flex;
        flex-direction: column;
        width: 80%;
        padding-right: 10px;
        label{
           margin-bottom: 0!important; 
        //    font-size: 0.85rem;
        //    font-weight: bold;
           color: $Black;
           max-width: 95%;
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
           &.name {
               font-size: $subTooltip;
            //    font-weight: bold;
           }
           &.msg{
            // color: $Black;
            // font-weight: normal;
            font-size: $tooltip;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1; /* number of lines to show */
            -webkit-box-orient: vertical;
           }
        }
           @media (max-width:992px) {

           }
           @media (max-width:768px) {
            width: 78%;
           }
    }
}
.box-customer-noti{
    border: 1px solid $secondaryColor;
    // background-color: $secondaryColor;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    justify-content: space-between;
    &.active-box{
        background-color: $Grey;
    }
    .right-item{
        height: 70px;
    }
    .box-img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;
        img{
            height: 40px;
            width: 40px;
            border-radius: 50%;
        }
        .social{
            position: absolute;
            bottom: -1px;
            right: -3px;
            width: 1.15rem;
            height: 1.15rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 0.75rem;
            z-index: 999;
            &.line{
                background-color: $Line;
            }
            &.facebook{
                background-color: $Facebook;
            }
            &.phone{
                background-color: $primaryColor;
            }
            i::before{
                font-size: 0.75rem;
                color: #ffffff;
            }
        }
    }
    .detail{
        display: flex;
        flex-direction: column;
        max-width: 82%;
        min-width: 82%;
        // padding-right: 10px;
        position: relative;
        font-weight: bold;
        font-size: $subTooltip;
        label{
           margin-bottom: 0!important; 
           font-size: $subTooltip;
           color: $primaryColor;
           font-weight: bold;
           max-width: 95%;
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
           &.msg{
            color: $Black;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
           }
        }
        span{
            margin-bottom: 0!important; 
            // font-size: 0.85rem;
            color: $primaryColor;
            font-weight: bold;
            max-width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.msg{
             color: $Black;
             font-weight: bold;
            }
         }
        @media (max-width:992px) {

        }
        @media (max-width:768px) {
            max-width: 78%;
            min-width: 78%;
        }
    }
}
.wrap-customer{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 87vh;
    // height: 80vh;
    // overflow-y: scroll;
    background-color: #ffffff;
    border-radius: 5px;
    border-bottom: 1px solid $Grey;
}
.left-item{
    display: flex;
    flex-direction: column;
    .left-item-top{
        display: flex;
        flex-direction: row;
        // width: max-content;
        justify-content: flex-end;
    }
    .left-item-bottom{
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        line-height: 15px;
    }
    
}

.text-time{
    text-align: right;
    // font-size: 0.75rem;
    // line-height: 1.5rem;
    padding-bottom: 0.3rem;
}

.right-item{
    display: flex;
    flex-direction: row;
    max-width: 70%;
    min-width: 70%;
    @media (max-width:768px) {
        max-width: 78%;
        min-width: 78%;
    }
}
.noti-alert {
    min-width: 10px;
    max-width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $noti;
    display: block;
    // margin-left: 5px;
    position: absolute;
    margin-top: 1.1rem;
}
.btn-resolve{
    color: #ffffff;
    background-color: $primaryColor;
    border-color: $primaryColor;
    &:hover{
        color: rgba(255,255,255,0.65) !important;
    }
    &:focus{
        box-shadow: none !important;
        outline: none!important;
    }
    &.active{
        background-color: #cccccc;
        border-color: #cccccc;
        color: #ffffff;
        }
    }
.follow-up{
    background-color: $primaryColor;
    color: #ffffff;
    font-size: 12px;
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    display: block;
    margin-right: 15px;
    i{
        padding-right: 0.25rem;
    }
}
.btn-follow-up{
    background-color: #27AE60;
    border-color: #27AE60;
    color: #ffffff;
    &:hover{
        color: rgba(255,255,255,0.65) !important;
    }
    &:focus{
        box-shadow: none !important;
        outline: none!important;
    }
    &.active{
    background-color: #cccccc;
    border-color: #cccccc;
    color: #ffffff;
    }
}


 .wrap-customer::-webkit-scrollbar {
    width: .4em; 
  }
  .wrap-customer::-webkit-scrollbar,
  .wrap-customer::-webkit-scrollbar-thumb {
    overflow:visible;
    border-radius: 4px;
  }
  .wrap-customer::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.2); 
  }
  .cover-bar {
    position: absolute;
    background: #fff;
    height: 100%;  
    top: 0;
    right: 0;
    width: .4em;
    border-radius: 5px !important;
    -webkit-transition: all .5s;
    opacity: 1;
  }

  .wrap-customer:hover .cover-bar {
     opacity: 0;
    -webkit-transition: all .5s;
  }
  .btn-gray{
      background-color: #cccccc;
      border-color: #cccccc;
      color: $Black;
      &:focus{
          box-shadow: none!important;
      }
  }
  .btn-tag{
      border-color: $primaryColor;
      background-color: $primaryColor;
      color: #fff;
      padding: .25rem .5rem !important;
      font-size: 0.75rem !important;
      margin: 0.25rem;
      &.active{
        background-color: #FDB513;
        border-color: #FDB513;
        color: $Black;
        &:hover{
            color: rgba(0, 0, 0, 0.65) !important;
        }
      }
      &:hover{
        color: rgba(255, 255, 255, 0.65) !important;
      }
    //   &:focus{
    //       background-color: #FDB513;
    //       border-color: #FDB513;
    //       color: $Black;
    //       box-shadow: none !important;
    //   }
  }

   
    .span-tags{
        background-color: $primaryColor;
        color: #ffffff!important;
        font-size: 0.75rem !important;
        padding: 0.159rem  0.45rem !important;
        border-radius: 0.25rem!important;
        margin-left: 5px;
    }

    .haeder-search{
        display: flex;
        flex-direction: row;
        width: 100%;
        .input-group{
            padding:1rem 0 0 0;
            .input-group-prepend{
                left: 0;
                button{
                    margin: 0;
                    box-shadow: none;
                    position: absolute;
                    width: auto;
                    height: auto;
                    min-width: auto;
                    color: $Darkgrey;
                    // z-index: 99;
                }
                .btn-close{
                    color: $Black !important;
                    right: 1rem;
                }
            }
            input{
                width: 100%;
                padding-left: 40px;
                padding-right:  40px;
                text-align: left;
            }
            .form-control{
                border: 1px solid $secondaryColor !important;
                border-radius: 5px !important;
            }
        }
    }
    .haeder-cus{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        .haeder{
            font-weight: bold;
        }
        .add-cus{
            min-width: max-content;
            font-size: 1rem;
            padding: 0;
            color: $primaryColor;
            i{
                padding-right: 0.5rem;
                font-size: 1.25rem;
            }

        }
    }
    .btn-send{
        color: #ffffff !important;
        background-color: $primaryColor !important;
        border-color: $primaryColor !important;
        border-radius: 5px !important;
        i{
            padding-right: 0.5rem;
        }
    }

    .check-social{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 15px 0 15px;
        align-items: center;
        .form-group{
            margin-bottom: 0;
            width: max-content;
            label{
                font-size:12px;
                span{
                    padding-left: 25px;
                }
            }
        }

        [type="radio"]:checked + label{ 
            color: $primaryColor;
        }

        .radio-custom-modal:checked + :before{
            font-size: 1.25rem;
            line-height: 1rem;
           
        }
        .radio-custom-modal + .radio-custom-modal-label:before{
            font-size: 1.25rem;
            line-height: 1rem;
        }
    }
    .box-frist{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 86vh;
        img{
            width: 82px;
        }
        .box-text{
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            label{
                margin-bottom: 10px;
                font-size: 50px;
                color: $Grey;
                font-weight: bold;
            }
        }

    }

   