.selected-product {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  border-radius: 5px;

  .product-container {
    display: grid;
    grid-template-columns: 60px auto 15% 10% 10% 5%;
    padding-bottom: 16px;
    border-bottom: 1px solid #e9e9e9;

    * {
      display: flex;
      align-items: center;
    }

    .product-image {
      img {
        width: 48px;
        border-radius: 5px;
      }
    }

    .product-price{
      justify-content: flex-end;
      // margin-right: 56px;
    }

    .product-remains{
      justify-content: flex-end;
      // margin-right: 42px;
    }

    .product-status {
      justify-content: center;

      span {
        padding: 4px 8px;
        border-radius: 8px;
        background: var(--theme-primary-bg, #f9fbef);
        color: #b2d234;
      }

      span.not-show {
        background: var(--default-action-divider, #e9e9e9);
        color: #444450;
      }
    }

    .product-delete{
      text-align: center;

      &:hover{
        cursor: pointer;
      }
    }
  }

  .text-sku{
    margin-left: 16px;
  }

  .productSkus-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .productSkus {
      display: grid;
      grid-template-columns: 60px auto 15% 10% 10% 5%;
      border-radius: 5px;
      padding: 8px 0;
      background-color: #f9f9f9;

      * {
        display: flex;
        align-items: center;
      }

      .productSku-checkbox {
        grid-column: 1 / 2;
        justify-content: center;
        padding-left: 16px;
      }

      .productSku-detail {
        grid-column: 2 / 3;

        img {
          width: 48px;
          border-radius: 5px;
          margin-right: 5px;
        }
      }

      .productSku-price {
        grid-column: 3 / 4;
        justify-content: flex-end;
        // margin-right: 56px;
      }

      .productSku-amount {
        grid-column: 4 / 5;
        justify-content: flex-end;
        // margin-right: 42px;
      }

      .productSku-status {
        grid-column: 5 / 6;
        justify-content: center;

        span {
          padding: 4px 8px;
          border-radius: 8px;
          background: var(--theme-primary-bg, #f9fbef);
          color: #b2d234;
        }

        span.not-show {
          background: var(--default-action-divider, #e9e9e9);
          color: #444450;
        }
      }
    }
  }
}
