@import "./variables";

#manage-live {
    .manage-live-container {
        width     : 100%;
        height    : 100%;
        min-height: 85vh;
        // background-color: wheat;
    }

    .pic-live-container {
        background-color: #383633;
        width           : 100%;
        height          : 80vh;
        position        : relative;

        .confirm-modal {

            position: absolute;
            width           : 100%;
            height          : 100%;
            background-color: rgba(0,0,0, 0.8);
            z-index         : 103;

            &-container {
                display        : flex;
                justify-content: center;
                flex-direction : column;
                align-items    : center;
                width          : 100%;
                height         : 100%;
    
                p {
                    color: white;
                    font-size: 1.5rem;
                }
            }
            
        }
      
        .setting {
            position: absolute;
            bottom  : 1rem;
            left    : 1rem;
            z-index : 102;
        }

        .back-layer {
            z-index : 99 !important;
        }

    }

    .img-profile-manage {
        position     : relative;
        width        : 5em;
        height       : 5em;
        border-radius: 50%;
        overflow     : hidden;
    }

    .manage-live-items {
        border: 1px solid black;
        min-width: 100%;
    }
    .item-detail-container {
        // width     : 35%;
        height    : 100%;
        position  : relative;
        overflow  : scroll;
        max-height: 85vh;
        padding   : 0 1em;
    }

    .item-detail-card {
        border-bottom: 1px solid #eeeeee;
    }

    .img-shelf {
        width: 6em;
    }

    .border-card-manage {
        background   : #ffffff;
        box-shadow   : 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }

    .item-detail-container > div {
        margin-bottom: 1em;
    }
    .item-detail-container > div:last-child {
        margin-bottom: 0.5em;
    }

    .vl {
        border-left: 1px solid #eeeeee;
        height: 90%;
    }

    .input-chat-manage {
        width: 84%;
        bottom: 40px;
        left: 40px;
    }

    .bg-live {
        padding      : 1px 0.5em;
        background   : linear-gradient(276.74deg, #ED1072 -55.02%, rgba(255, 255, 255, 0) 100%), #D10808;
        border-radius: 6px;
        color        : #ffff;
    }

    .gif-live {
        width: 1em;
        background: linear-gradient(269.33deg, #ED1072 -151.98%, rgba(255, 255, 255, 0) 60.57%);
        border-radius: 6px;
        margin-right: 4px;
    }


    .close {
        position: absolute;
        right   : 22px;
        top     : 10px;
        width   : 32px;
        height  : 32px;
        opacity : 0.3;
        cursor  : pointer;
      }
      .close:hover {
        opacity: 1;
      }
      .close:before,
      .close:after {
        position        : absolute;
        left            : 15px;
        content         : " ";
        height          : 33px;
        width           : 2px;
        background-color: #ffffff;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
      

}

.video00 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
}

video.video-react-video {
    width: 100%;
    position: absolute;
    height: 100%;
}

.video-react-controls-enabled.video-react-has-started.video-react-playing.video-react-user-active.video-react-workinghover.video-react {
    position: relative;
    background-color: black;
    width: 50% !important;
    height: 80vh !important;
}

button.video-react-big-play-button.video-react-big-play-button-left.big-play-button-hide {
    display: none;
}

.chat-wrap {
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    background: #383838;
    height: 100%;
    z-index: 90;
}

.overlay-background {
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    position: absolute;
    background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(0 ,0, 0));
}
                              
                                    
                                    


