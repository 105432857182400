.container-gallery {
  .header {
    background-color: white;
    padding: 30px;
    .content-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.container-select-image {
  .group-header {
    display: flex;
    flex-direction: row;
    // align-items: center;

    .avatar {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }

    .name {
      margin-left: 8px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .bg-white {
    background-color: white;
  }

  .header {
    padding: 30px;
    .content-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
    }
  }

  .container-select-all {

        display: inline-flex;
    justify-content: flex-end;
    padding-right: 15px;
    width: 100%;

  }

  .footer-action {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    height: 60px;
    z-index: 999;

    .btn-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 50px;
    }


  }
}

.ellipsis {
    overflow:hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
}


