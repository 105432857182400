
.modal-dialog-confirm{
    margin-top: 0;
    .modal-body {
        // height: 20em;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-flow: column;
        border-radius: 5px;
        gap: 5em;

        .header-wrap {
            display: flex;
            gap: 20px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
                width: 80px;
            }
        }
    }

    .modal-header{
        .close {
            display: none;
        }
        .modal-title {
            width: 100%;
        }
    }


}

