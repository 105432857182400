@import './variables';



.product-card-chat {
  bottom: 25%;

  &.large {
    bottom: 22%;
  }

  position: absolute;
  width: -webkit-fill-available;
  height: auto;
  z-index: 1000;
}

.product-card-msg-chat {
  position: absolute;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.product-card-chat-box {
  position: relative;
  width: 100%;
  background-color: $White;
  height: inherit;
  margin-bottom: 1rem;

  .bg-image-product-chat {
    position: absolute;
    z-index: 998;
    bottom: 0;
    height: 100%;
    .bg-product {
      background-color: transparent !important;
      padding: 0 !important;
      width: auto;
      height: 100%;
    }
  }

  .close-product-card-chat {
    position: absolute;
    right: 0.75rem;
    top: 0;
    z-index: 1000;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  .content-product-card-chat {
    padding: 0.5rem;
    position: relative;
    z-index: 999;
  }
  .img-box-product-card-chat {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    .img-product-card {
      background-color: transparent !important;
      border: none !important;
       padding: 0 !important;
       border-radius: 0 !important;
      width: 100%;
      height: auto;
    }
  }

  .product-price-item-chat {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem;

    label {
      border: none !important;
      margin: 0 !important;
      padding: 0 !important;
      @include primary-font;
      font-style: normal;
      background-color: transparent !important;
      // align-self: left;
    }

    .product-name-chat {
      font-weight: 600;
      // font-size: $small;
      font-size: $subTooltip;
      // color: $Black;
      text-align: start;
    }

    .product-price-chat {
      font-weight: 600;
      // font-size: $small;
      font-size: $subTooltip;
      color: $Red;
    }

    .product-price-old-chat {
      font-weight: normal;
      font-size: $note;
      color: $DarkGrey;
      text-decoration: line-through;
    }

    .text-color-primary{
      color: $primaryColor !important;
    }
    .text-color-black {
      color: $defaultTextPrimary;
    }
    .text-color-red {
      color: $Red;
    }
  }

  .footer-card-chat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .txt-link-chat {
    @include primary-font;
    font-size: $small;
    color: $BlueDiscount;
    font-style: normal;
    font-weight: 400;
  }
}