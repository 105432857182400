@import "../../styles/variables";

.wrap-list-card-shop {
  background-color: white;

  .cover-search {
    margin-bottom: 12px;
    margin-right: 6px;
  }

  .cover-list-card-shop {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 2px;
    height: calc(90vh - 275px);
    padding-bottom: 10px;
  }

  .cover-search-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10rem 0rem;

    .text-search-empty {
      font-size: $subTitle2;
      color: $defaultTextTertiary;
      font-weight: 700;
      margin-top: 1rem;
    }
  }
}
