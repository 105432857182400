@import './variables';

.playlists {
  span.total-playlists {
    font-size: 1rem;
    font-style: normal;
    font-weight: 200;
    margin-left: 1rem;
  }
  .playlists-content {
    padding: 1rem;
    .content-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .no-playlists {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 75vh;
    }
  }

  .pagination-top {
    display: flow-root;
    margin-bottom: 10px;
    margin-top: 1em;
  }
}

.modal-body {
  padding: 3rem 1rem !important;
}

.container-modal {
  top: 0 !important;
  .content {
    margin: 1.5rem 0.5rem !important;
  }
  .btn-box {
    padding: 0.2rem 3rem !important;
  }
}

.clear-btn {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  color: $Orange;
  cursor: pointer;
}

.upload-playlist-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload-playlist {
  .image-playlist-container {
    position: relative;
    // margin-right: 0.5em;
    // margin-top: 0.5em;
    background-color: #fdf3e8;
    border-radius: 10px;
    border: 1px dashed #9d9d9d;
  }

  .image-playlist {
    opacity: 1;
    min-width: 12em;
    min-height: 12em;
    max-width: 12em;
    max-height: 12em;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
    border-radius: 10px;

    &.front-page {
      min-width: 9em;
      min-height: 9em;
      max-width: 9em;
      max-height: 9em;
    }
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .image-playlist-container:hover .image-playlist {
    opacity: 0.3;
  }

  .image-playlist-container:hover .middle {
    opacity: 1;
  }

  .clear-image {
    right: 0;
    position: absolute;
  }

  .button-upload-playlist-box {
    width: 100%;
    height: 100%;
    background-color: $AISPrimaryBg;
    border: 1px dashed #9d9d9d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: relative;

    .file-upload {
      opacity: 0;
      position: absolute;
      z-index: 1;
      // background-color: lightcoral;
      width: 100%;
      height: 100%;
      font-size: 5px;
    }

    // &.disabled {
    //   background: #f9f9f9;
    //   box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);

    //   .icon {
    //     width: 1.5em;

    //     filter: grayscale(0.99) brightness(1.25);
    //   }
    //   .title {
    //     color: #646464;
    //   }
    // }

    .icon-camera {
      svg {
        width: 2em;
        height: 1.8em;
        path {
          // fill: #ffffff;
        }
      }
    }

    .title {
      color: $AISPrimaryMain;
      font-family: 'DBHeavent' !important;
      font-style: normal;
      font-weight: 300;

      margin: 0.8em 0 0 0;
    }
  }
}

.playlists-list {
  margin: 1rem 0;

  a {
    color: gray;
  }

  .playlists-logo img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .col-1-atl {
    flex: 0 0 10.333333%;
    max-width: 10.333333%;
  }
  .col-3-atl {
    flex: 0 0 29.333333%;
    max-width: 29.333333%;
  }

  .align-center {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .table-header {
    background-color: $defaultBMain;
    align-items: baseline;
    font-size: $caption;

    padding: 1rem;

    &.small {
      padding: 0.5rem;
    }
    color: white;
    border-radius: 6px 6px 0 0;
  }

  .table-body {
    padding: 0 1rem;
    font-family: 'DBHeavent' !important;
    font-style: normal;
    font-weight: 300;
    align-items: baseline;

    &.scroll {
      height: 300px;
      overflow: scroll;
    }

    .row {
      background-color: $White;
      margin-top: 1rem;
      padding: 1rem 0;

      &.small {
        padding: 0.5rem 0;
        margin-bottom: 0.5rem;
      }
    }

    .btn-add {
      .icon-plus {
        font-size: 1rem !important;
      }
    }

    .btn-close {
      align-items: center;
      display: flex;
      cursor: pointer;
      .icon-close {
        height: 1.25rem;
      }
    }
  }
  
}
.dropdownlist .Dropdown-control {
  @extend .info-text;
  @extend .font-primary;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding-left: 1rem;
    background-color: #f9f9f9;
    height: 44px;
    align-items: center;
    display: flex;
    font-size: $caption;
    color: #a09c9c;
  }

#playlist-categories {
  .content{
    .table-header {
      display: grid;
      grid-row: auto;
      grid-column: auto;
      grid-auto-rows: auto;
      grid-template-columns: 10% auto 18% 18% 15%;
      background: $defaultBMain;
      border-radius: 10px 10px 0px 0px;
      color: #FFFFFF;
      padding: 1em;
      margin-top: 1em;
      text-align: center;
      word-break: break-word;
      span {
          padding: 10px;
      }
      div {
          padding: 10px;
      }
      div span {
          padding: 0;
      }
    }
    .table-content {
      pointer-events: auto;
      display: grid;
      grid-row: auto;
      grid-column: auto;
      grid-auto-rows: auto;
      grid-template-columns: 10% auto 18% 18% 15%;
      background: #FFFFFF;
      // border-radius: 10px 10px 0px 0px;
      color: #383633;
      padding: 1em;
      margin-top: 1em;
      text-align: center;
      word-break: break-word;
      span {
          padding: 10px;
      }
      div {
          padding: 10px;
      }
      div span {
          padding: 0;
      }
    }
}

.modal-table-header {
  display: grid;
  grid-row: auto;
  grid-column: auto;
  grid-auto-rows: auto;
  grid-template-columns: 10% auto 18% 18% 15%;
  background: $defaultBMain;
  border-radius: 10px 10px 0px 0px;
  color: #FFFFFF;
  padding: 1em;
  margin-top: 1em;
  text-align: center;
  word-break: break-word;
  span {
      padding: 10px;
  }
  div {
      padding: 10px;
  }
  div span {
      padding: 0;
  }
}
.modal-table-content {
  display: grid;
  grid-row: auto;
  grid-column: auto;
  grid-auto-rows: auto;
  grid-template-columns: 10% auto 18% 18% 15%;
  background: #FFFFFF;
  // border-radius: 10px 10px 0px 0px;
  color: #383633;
  padding: 1em;
  margin-top: 1em;
  text-align: center;
  word-break: break-word;
  span {
      padding: 10px;
  }
  div {
      padding: 10px;
  }
  div span {
      padding: 0;
  }
}


}
