@import '../styles/variables';

#order-list-delay{
.table-header {
    display: grid;
       grid-row: auto;
       grid-column: auto;
       grid-auto-rows: auto;
       grid-template-columns: 5.33% auto 8.33% 8.33% 9.33% 8.33% 9.33% 8.33% 9.33% 10.33% 7.33% 7.33%;
       background: $defaultBMain;
       border-radius: 10px 10px 0px 0px;
       color: $White;
    //    padding: 1em;
       margin-top: 1em;
       text-align: center;
       word-break: break-word;
       font-size: medium;
       font-weight: bold;
       span {
           padding: 10px;
       }
       div {
           padding: 10px;
       }
       div span {
           padding: 0;
       }
    }
    .table-content {
        pointer-events: auto;
        display: grid;
           grid-row: auto;
           grid-column: auto;
           grid-auto-rows: auto;
           grid-template-columns: 5.33% auto 8.33% 8.33% 9.33% 8.33% 9.33% 8.33% 9.33% 10.33% 7.33% 7.33%;
           background: $White;
        //    border-radius: 10px 0px 0px 0px;
           color: $defaultTextPrimary;
        //    padding: 1em;
           margin-top: 1em;
           text-align: center;
           word-break: break-word;
           align-items: center;
           span {
               padding: 10px;
           }
           div {
               padding: 10px;
           }
           div span {
               padding: 0;
           }
        }

        @media screen and (max-width: 1000px){
            width: 50em;
            overflow-x: auto;
        }
        .badge {
            padding: 5px;
            white-space: pre-wrap;
        }

        .wait-payment {
            background-color: $disableGray;
            color: $textGray;
        }
        .wait-approve {
            background-color: $defaultWarningBg;
            color: $defaultWarningMain;
        }
        .wait-return {
            background-color: $defaultErrorBg;
            color: $defaultErrorMain;
        }
        .success-order {
            background-color: $lightGreen;
            color: $success;
            display: flex;
            align-content: baseline;
            gap: 4px;
            justify-content: center;
        }

        .icon-no-item {
            width: 100%;
            height: 20em;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }