@import './variables';

.icon-eye {
    position: absolute;
    right: 4%;
    bottom: 18%;
    cursor: pointer;
    path {
        fill: $defaultTextPrimary;
    }
}

.icon-eye-open {
    position: absolute;
    right: 2%;
    bottom: 0%;
    cursor: pointer;
    path {
        fill: $defaultTextPrimary;
    }
}

.change-password-page{
    height: 100vh;
    background-color: $defaultBg;

.container-change-password{
    background-color: $White;
    width: 50%;
    height: 80%;
    @media only screen and (max-width: 700px){
        width: 100%;
    }
}

.content-change-password{
    padding: 5em 9em;
    @media only screen and (max-width: 1366px){
        padding: 1em;
    }
}

.container-button {
    flex-direction: row;
    @media only screen and (max-width: 1366px){
        flex-direction: column;
        gap: 30px;
    }
}

.form-control {
    background-color: white; 
}
}
