.container-content {
  background-color: #f9f9f9;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  border: 1px dashed #BFBFBF;

  .image-list {
    display: flex;
    flex-wrap: wrap;

    .image-thumbnal {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 1rem;
      }
      img {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border: solid #fff 4px;
        width: 100%;
      }
    }
    .ratio16-9 {
      flex: 100%;
      max-width: 100%;
      padding: 1rem 4px;
    }
    .ratio4-3 {
      flex: 25%;
      max-width: 25%;
      padding: 1rem 4px;
    }
  }

  .thumbnail {
    padding: 0px !important;
    position: relative;
    margin-bottom: 25px;
    border: none;
    border-radius: 0;
    display: block;

    img {
     width: 100%;
     height: 100%;
     margin-bottom: 0px;
     display: block;
   }
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    padding: 0px 0px 0px 0px;
    transition: 0.5s ease;
    background-color: rgba(26, 26, 26, 0.6);
    display: flex;
    justify-content: flex-end;

    img.remove-item {
      width: 40px !important;
      height: 40px !important;
      border: none !important;
    }
    img.remove-item:hover {
      cursor: pointer;
    }
  }

  .thumbnail:hover .overlay {
    opacity: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }


}
